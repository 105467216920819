// import React, { useEffect, useState } from 'react'

// // var __html = require('./test.html');
// // var template = { __html: __html };

// const Test = () => {
//   // console.log(template);

//   let[htmlFileString, setHtmlFileString] = useState('');

//   async function fetchHtml() {
//     setHtmlFileString(await (await fetch(`presentation.html`)).text());
//   }
//   useEffect(() => {
//     fetchHtml();
//   }, []);

//   // console.log(htmlFileString);

//   useEffect(() => {
//     // Include external JS
//     const script = document.createElement('script');
//     script.src = './app.js';
//     script.async = true;
//     document.body.appendChild(script);
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);
  

//   return (
//     <>
//       test

//       {/* <div dangerouslySetInnerHTML={template} /> */}
      
//       {/* @ts-ignore */}
//       {/* <iframe src={template}></iframe>  */}
      
//       {/* <div dangerouslySetInnerHTML={{ __html: __html }} ></div> */}


//       {/* it works (html file should be in public directory) */}
//       {/* <div id='naam'> 
//       </div> */}
      
//   {/* html css js is loading in this one */}
//       {/* <iframe src="presentation.html" width='100%' height='800px'></iframe> */}
//       <iframe src="presentation.html" width='100%' height='800px'></iframe>



//   {/* js doesn't load in this one (html css works fine) */}
//       {/* <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div> */}





//     </>
//   )
// }

// export default Test



import React, { useEffect, useState, useRef } from 'react';

const Test = () => {
  // const [htmlContent, setHtmlContent] = useState('');
  // const containerRef = useRef(null);

  // useEffect(() => {
  //   // Fetch the external HTML file
  //   fetch('InceptionProject/presentation.html')
  //     .then(response => response.text())
  //     .then(data => setHtmlContent(data))
  //     .catch(error => console.error('Error fetching HTML:', error));
  // }, []);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     // Load scripts sequentially
  //     const loadScript = (src: string, callback: any) => {
  //       const script = document.createElement('script');
  //       script.src = src;
  //       script.async = true;
  //       script.onload = callback;
  //       document.body.appendChild(script);
  //     };

  //     loadScript('/app.js', () => {
  //       console.log('Script loaded and executed.');
  //       // Additional logic if needed after script loads
  //     });

  //     return () => {
  //       const script = document.querySelector(`InceptionProject/app.js"]`);
  //       if (script) {
  //         document.body.removeChild(script);
  //       }
  //     };
  //   }
  // }, [htmlContent]);


  return (
    // <div
    //   ref={containerRef}
    //   dangerouslySetInnerHTML={{ __html: htmlContent }}
    // />
      <iframe src="InceptionProject/presentation.html" width='100%' style={{height: '100vh'}}></iframe>

  );
};

export default Test