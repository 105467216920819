import pdfFile from '../../assets/pdf/IF_FMV_Calculation.pdf';


export const Pdf2 = () => {

    return (
        <>
            <iframe
                src={pdfFile}
                style={{ width: '100%', height: '100vh' }}
            />
        </>
    )
}