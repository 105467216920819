import clsx from "clsx";
// import { Logo } from "../../assets";
import Stepline from "../Stepline/steper";
import styles from "./index.module.css";
import Logo from "../Logo/Logo";
import React, { useState } from "react";
import Button from "../Button";
type POSITION = "start" | "center";
interface Props {
  step: number;
  logoPosition?: POSITION;
  childClass?: string;
  isLogo?: boolean;
  containerClass?: string;
  marginBottom?: string;
  onPreviousStep?: () => void;
}
const Stepper = (props: Props) => {
  const {
    step,
    logoPosition = "center",
    childClass,
    containerClass = "",
    marginBottom = "",
    onPreviousStep,
  } = props;
  const [currentStep, setCurrentStep] = useState(step);
  const navigateToStep = (newStep: number) => {
    setCurrentStep(newStep);
  };
  return (
    <Stepline
      step={currentStep}
      onNavigateToStep={navigateToStep}
      stepTitles={[]}
    />
  );
};
export default Stepper;
