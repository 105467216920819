import Modal from "../Modal";
import Button from "../ButtonV2";
import styles from "./index.module.css";
import { backStep } from "../../store/reducers/stepReducer";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import {
  useGetNdaValueQuery,
  useUpdateStagesMutation,
} from "../../services/api";
import useStep from "../../hooks/useStep";
import { setStage } from "../../store/reducers/stageReducer";

function AgreementModal() {
  const dispatch = useAppDispatch();
  const { setStepFunc } = useStep();
  const stage = useAppSelector((state: RootState) => state.stage);
  const zohoCustomerId = useAppSelector(
    (state: RootState) => state.login.zohoCustomerId
  );

  const [updateStage] = useUpdateStagesMutation();
  const { data, isLoading } = useGetNdaValueQuery();

  const handleClick = async () => {
    const newStage = {
      ...stage,
      purlNdaChecked: Date.now().toString(),
    };
    dispatch(setStage(newStage));
    const step = await setStepFunc(newStage);

    const updatedStage = await updateStage({
      zohoId: zohoCustomerId,
      key: "purlNdaChecked",
    });
  };

  return (
    <Modal show={true} onClose={() => {}}>
      <div className={styles.container}>
        {isLoading ? (
          <h1 className="text-white flex justify-center mt-12">
            <Button isLoading={isLoading} title="" variant="light6" />
          </h1>
        ) : (
          <>
            <div className={styles.modalContainer}>
              <div className={styles.modalText}>{data?.data?.title}</div>

              <div className={styles.modalDesc}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.data?.description as unknown as TrustedHTML,
                  }}
                  className={styles.modaldecription}
                ></div>
              </div>
            </div>
            <div className={styles.modalButtonContainer}>
              <button
                className={styles.modalButton}
                onClick={() => {
                  dispatch(backStep());
                  localStorage.clear();
                }}
              >
                DISAGREE
              </button>
              <button className={styles.modalButton1} onClick={handleClick}>
                AGREE
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default AgreementModal;
