import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import img from "../../../assets/images/InceptionFinicallyLogo.png";
import styles from "./index.module.css";
import CustomSwitch from "../../CustomSwitch";
import { proformaFormSchema } from "../../../yup";
import { useGetStatesInfoQuery } from "../../../services/api";
import Select from "react-select";
import config from "../../../configs/env.development";
import { useNavigate } from "react-router-dom";
import { resetProformaUser } from "../../../store/reducers/proformaReducer";
import { useDispatch, useSelector } from "react-redux";
import useProformaValues from "../../../hooks/useSetProformaValues";
import Loader from "../../Loader";
import ProformaResponse from "../../ProformaResponse";
import { RootState } from "../../../store/store";
import { useGetDefaultProformaValuesQuery } from "../../../services/proformaApi";

interface IToggleSwitch {
  isAmpVantageActive: boolean;
  isUseAvailableTaxCredit: boolean;
  isUseAdjustedGrossIncome: boolean;
  isLossLimitation: boolean;
  isFederalRevenueSpread: boolean;
  isFederalBonusDepreciation: boolean;
  [field: string]: boolean;
}
interface State {
  _id: string;
  state: string;
}

interface StateOption {
  label: string;
  value: string;
}
const ProformaForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setProformaValues } = useProformaValues();
  const [toggleSwitch, setToggleSwitch] = useState<IToggleSwitch>({
    isAmpVantageActive: false,
    isUseAvailableTaxCredit: false,
    isUseAdjustedGrossIncome: false,
    isLossLimitation: true,
    isFederalRevenueSpread: false,
    isFederalBonusDepreciation: false,
  });
  const profromaUserDetails = useSelector(
    (state: RootState) => state.proformaUser
  );
  const isExternal = profromaUserDetails?.designation === "External";
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(proformaFormSchema),
    mode: "onChange",
    context: {
      isEquity:
        !toggleSwitch.isUseAvailableTaxCredit &&
        !toggleSwitch.isUseAdjustedGrossIncome,
      isGross: toggleSwitch.isUseAdjustedGrossIncome,
      isITC: !toggleSwitch.isUseAvailableTaxCredit,
      isExternal: isExternal,
    },
    defaultValues: {
      year1DepreciationUsed: "$0",
      stateSelector: {
        label: "California",
        value: "California",
      },
      stateTaxRate: "13.30%",
      stateTaxRateYr7to20: "13.30%",
      statePTEPayment: "9.30%",
      stateBonusDepreciation: false,
      stateBonusDepreciationAsFED: false,
      stateRevenueSpread: true,
      inceptionDevFee: "20%",
      developerDevFee: "13.60%",
      ITCPercentage: "34%",
      federalMarginalTaxRate: "37%",
      monthlyPlacedInService: "1",
      federalBonusDepreciationPercentage: "60%",
    },
  });

  const { data: states, isLoading: loading } = useGetStatesInfoQuery();
  const { data: defaultValues } = useGetDefaultProformaValuesQuery();
  const [stateOptions, setStateOptions] = useState<StateOption[]>();
  const [months] = useState([
    ...Array.from({ length: 12 }, (_, i) => `${i + 1}`),
  ]);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [driveFolderId, setDriveFolderId] = useState<DriveIdReturn>({});
  const [dataToPrefill, setDataToPrefill] = useState<string[][]>([]);

  const getStates = () => {
    const data = states?.data || [];
    const stateOption: StateOption[] = data.map((item: State) => {
      return {
        label: item.state,
        value: item._id,
      };
    });
    setStateOptions(stateOption);
  };

  const handleClick = (field: string) => {
    let newObj = { ...toggleSwitch, [field]: !toggleSwitch[field] };
    if (field === "isAmpVantageActive") {
      newObj.isLossLimitation = !newObj.isAmpVantageActive;
      newObj.isFederalRevenueSpread = newObj.isAmpVantageActive;
    }
    setToggleSwitch(newObj);
  };

  const onSubmit = async (values: ProformaForm) => {
    if (!toggleSwitch.isUseAdjustedGrossIncome) {
      setValue("adjustedGrossIncome", "");
    }
    if (!toggleSwitch.isUseAvailableTaxCredit) {
      setValue("ITCAmount", "");
    }
    if (
      toggleSwitch.isUseAvailableTaxCredit ||
      toggleSwitch.isUseAdjustedGrossIncome
    ) {
      setValue("equityContribution", "$100000");
    }
    if (!toggleSwitch.isFederalRevenueSpread) {
      setValue("monthlyPlacedInService", "");
    }
    if (!toggleSwitch.isFederalBonusDepreciation) {
      setValue("federalBonusDepreciationPercentage", "");
    }
    if (isExternal) {
      const newInceptionDevFee =
        parseFloat(profromaUserDetails?.inceptionDevFee!) +
        parseFloat(values.partnerMarkup!.replace(/%/g, ""));
      // setValue("inceptionDevFee", `${newInceptionDevFee.toString()}%`);
      values.inceptionDevFee = `${newInceptionDevFee.toString()}%`;
    }
    try {
      setIsLoadingSubmit(true);
      const valuesToUpdate = setProformaValues(values, toggleSwitch);

      if (toggleSwitch.isAmpVantageActive) {
        const data = {
          driveId: config.ampVantageFolderId,
          additionalPdfFolderId: config.ampVantagePdfFolderId,
          fileId: config.mainSpreadsheetId,
          folderName: values.clientName,
          values: valuesToUpdate,
          scenarioName: values.scenarioName,
          slideId: config.slideId,
        };
        const response = await fetch(
          `${config.baseUrl}/api/proforma/create-ampvantage`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response.json();
        setDriveFolderId(res);
      } else {
        const folderIdtoSave = isExternal ? profromaUserDetails?.folderId : "";

        const data = {
          driveId: defaultValues?.data[0]?.folderId!,
          externalUserFolderId: folderIdtoSave,
          fileId: config.mainSpreadsheetId,
          folderName: values.clientName,
          values: valuesToUpdate,
          masterFileId: config.masterFileId,
          scenarioName: values.scenarioName,
        };
        const response = await fetch(
          `${config.baseUrl}/api/proforma/create-proforma-copy`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response.json();
        setDriveFolderId(res);
      }
    } catch (error) {
      console.log("Login error:", error);
      setErrMsg("An error occurred during login.");
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | boolean } }
  ) => {
    const { name, value } = e.target;
    if (name === "year1DepreciationUsed") {
      let rawValue = value
        .toString()
        .replace("$0", "")
        .replace(/[^\d.]/g, "");
      let formattedValue = "";
      if (rawValue === "") {
        formattedValue = `$0${rawValue}`;
      } else {
        formattedValue = `-$${rawValue}`;
      }
      setValue(name, formattedValue);
    } else if (
      name === "stateTaxRate" ||
      name === "stateTaxRateYr7to20" ||
      name === "statePTEPayment" ||
      name === "federalBonusDepreciationPercentage" ||
      name === "federalMarginalTaxRate" ||
      name === "inceptionDevFee" ||
      name === "ITCPercentage" ||
      name === "developerDevFee" ||
      name === "partnerMarkup"
    ) {
      let rawValue = value
        .toString()
        .replace("%", "")
        .replace(/[^\d.]/g, "");
      // const formattedValue = `${rawValue}%`;
      let formattedValue = "";
      if (rawValue === "") {
        formattedValue = `${rawValue}0%`;
      } else {
        if (rawValue[0] === "0") rawValue = rawValue.substring(1);
        formattedValue = `${rawValue}%`;
      }
      setValue(name, formattedValue);
    } else {
      setValue(name as keyof ProformaForm, value);
    }
    setErrMsg("");
  };
  const setInputFieldsOnStateChange = (selectedOption: StateOption) => {
    const targetState = states?.data.filter((state) => {
      return state._id === selectedOption.value;
    });
    console.log(targetState![0]);
    setValue(
      "stateTaxRate",
      `${targetState![0].stateTaxRatePercent.toString()}%`
    );
    setValue(
      "stateTaxRateYr7to20",
      `${targetState![0].stateTaxRatePercentYrs7_20.toString()}%`
    );
    setValue(
      "statePTEPayment",
      `${targetState![0].statePTEPaymentPercent.toString()}%`
    );
    setValue(
      "stateBonusDepreciation",
      targetState![0].stateBonusDepreciation === "NO" ? false : true
    );
    setValue(
      "stateBonusDepreciationAsFED",
      targetState![0].stateDepreciation === "NO" ? false : true
    );
    setValue(
      "stateRevenueSpread",
      targetState![0].stateRevenueSpread === "NO" ? false : true
    );
  };
  const getAssumptionData = async () => {
    const spreadsheetId = config.mainSpreadsheetId;

    try {
      setIsLoading(true);
      const response = await fetch(
        `${config.baseUrl}/api/proforma/get-assumptions-data/${spreadsheetId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setDataToPrefill(result.data);
    } catch (error) {
      console.log("Login error:", error);
      setErrMsg("An error occurred during login.");
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    const currentDate = new Date();
    reset({
      year1DepreciationUsed: dataToPrefill[13][1] || "$0",
      stateSelector: {
        label: dataToPrefill[18][1] || "California",
        value: dataToPrefill[18][1] || "California",
      },
      stateTaxRate: dataToPrefill[26][1] || "13.30%",
      stateTaxRateYr7to20: dataToPrefill[28][1] || "13.30%",
      statePTEPayment: dataToPrefill[30][1] || "9.30%",
      stateBonusDepreciation: dataToPrefill[36][1] === "Yes" ? true : false,
      stateBonusDepreciationAsFED:
        dataToPrefill[38][1] === "Yes" ? true : false,
      stateRevenueSpread: dataToPrefill[20][1] === "Yes" ? true : false,
      federalMarginalTaxRate: dataToPrefill[24][1] || "37%",
      monthlyPlacedInService: String(currentDate.getMonth() + 1),
      federalBonusDepreciationPercentage: dataToPrefill[34][1] || "60%",
      inceptionDevFee: isExternal
        ? `${profromaUserDetails.inceptionDevFee}%` || "20.7%"
        : `${defaultValues!.data[0].inceptionDevFee}%`,
      developerDevFee: isExternal
        ? `${profromaUserDetails.developerDevFee}%` || "10.34%"
        : `${defaultValues!.data[0].developerDevFee}%`,
      ITCPercentage: isExternal
        ? `${profromaUserDetails?.itcPercent}%` || "30%"
        : `${defaultValues!.data[0].itcPercent}%`,
    });
    let newObj = { ...toggleSwitch };
    newObj.isLossLimitation = dataToPrefill[5][1] === "Yes" ? true : false;
    newObj.isFederalRevenueSpread =
      dataToPrefill[9][1] === "Yes" ? true : false;
    newObj.isFederalBonusDepreciation =
      dataToPrefill[32][1] === "Yes" ? true : false;
    setToggleSwitch(newObj);
  };

  useEffect(() => {
    getStates();
  }, [states]);

  useEffect(() => {
    getAssumptionData();
  }, []);
  useEffect(() => {
    if (dataToPrefill.length > 0) {
      resetForm();
    }
  }, [dataToPrefill]);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(resetProformaUser());
    navigate("/proforma");
  };

  useEffect(() => {}, [driveFolderId]);
  if (driveFolderId && Object.keys(driveFolderId).length > 0) {
    return (
      <ProformaResponse
        href={driveFolderId}
        setDriveFolderId={setDriveFolderId}
        resetForm={resetForm}
      />
    );
  }
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div>
          <img src={img} alt="" className={`${styles.image}`} />
        </div>
        {profromaUserDetails?.designation !== "External" && (
          <div className={styles.switchBox}>
            <span className={styles.span}>AmpVantage</span>
            <CustomSwitch
              isActive={toggleSwitch.isAmpVantageActive}
              names="isAmpVantageActive"
              handleClick={handleClick}
            />
          </div>
        )}
        <div>
          {/* button */}
          <button
            onClick={handleLogout}
            className={`relative h-12 bg-[#3B755E] cursor-pointer rounded ${styles.logoutButton}`}
          >
            LOGOUT
          </button>
        </div>
      </div>
      {isLoading ? (
        <Loader isLoading={isLoading} size={48} />
      ) : (
        <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
          {isLoadingSubmit ? (
            <>
              <h1 className={`font-bold text-center ${styles.formHeader}`}>
                Generating your proforma
              </h1>
              <div className={styles.loaderBox}>
                <Loader isLoading={isLoadingSubmit} />
              </div>
            </>
          ) : (
            <>
              <h1 className={`font-bold text-center ${styles.formHeader}`}>
                Fill Out Your Information
              </h1>
              <div className={styles.formLineFull}>
                <p className={styles.inputLabel}>Client Name*</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="clientName"
                    className={styles.inputBox}
                    {...register("clientName")}
                  />
                  {errors.clientName && (
                    <p className={styles.error}>{errors.clientName.message}</p>
                  )}
                </div>
              </div>
              <div className={styles.formLineFull}>
                <p className={styles.inputLabel}>Scenario Name*</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="scenarioName"
                    className={styles.inputBox}
                    {...register("scenarioName")}
                  />
                  {errors.scenarioName && (
                    <p className={styles.error}>
                      {errors.scenarioName.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={styles.formLineFull}>
                <p className={styles.inputLabel}>Equity Contribution</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="equityContribution"
                    className={styles.inputBox}
                    disabled={
                      toggleSwitch.isUseAvailableTaxCredit ||
                      toggleSwitch.isUseAdjustedGrossIncome
                    }
                    {...register("equityContribution")}
                  />
                  {errors.equityContribution && (
                    <p className={styles.error}>
                      {errors.equityContribution.message}
                    </p>
                  )}
                </div>
              </div>
              {/* <div
                className={`${
                  toggleSwitch.isAmpVantageActive ? "!hidden" : ""
                } ${styles.formLineFull}`}
              >
                <span className={styles.inputLabel}>
                  Use Available Tax Credits*
                </span>
                <CustomSwitch
                  isActive={toggleSwitch.isUseAvailableTaxCredit}
                  names="isUseAvailableTaxCredit"
                  handleClick={handleClick}
                />
              </div> */}
              <div
                className={`${
                  toggleSwitch.isAmpVantageActive ||
                  !toggleSwitch.isUseAvailableTaxCredit
                    ? "!hidden"
                    : ""
                } ${styles.formLineFull}`}
              >
                <p className={styles.inputLabel}>ITC Amount</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="ITCAmount"
                    className={styles.inputBox}
                    {...register("ITCAmount")}
                  />
                  {errors.ITCAmount && (
                    <p className={styles.error}>{errors.ITCAmount.message}</p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull}`}>
                <span className={styles.inputLabel}>
                  Use Adjusted Gross Income*
                </span>
                <CustomSwitch
                  isActive={toggleSwitch.isUseAdjustedGrossIncome}
                  names="isUseAdjustedGrossIncome"
                  handleClick={handleClick}
                />
              </div>
              <div
                className={`${
                  !toggleSwitch.isUseAdjustedGrossIncome ? "!hidden" : ""
                } ${styles.formLineFull}`}
              >
                <p className={styles.inputLabel}>Adjusted Gross Income</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="adjustedGrossIncome"
                    className={styles.inputBox}
                    {...register("adjustedGrossIncome")}
                  />
                  {errors.adjustedGrossIncome && (
                    <p className={styles.error}>
                      {errors.adjustedGrossIncome.message}
                    </p>
                  )}
                </div>
              </div>
              {/* <div className={`${styles.formLineFull}`}>
                <p className={styles.inputLabel}>Year 1 Depreciation Used</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="year1DepreciationUsed"
                    className={styles.inputBox}
                    {...register("year1DepreciationUsed")}
                    onChange={onChange}
                  />
                  {errors.year1DepreciationUsed && (
                    <p className={styles.error}>
                      {errors.year1DepreciationUsed.message}
                    </p>
                  )}
                </div>
              </div> */}
              <div className={`${styles.formLineFull}`}>
                <span className={styles.inputLabel}>Loss Limitation*</span>
                <CustomSwitch
                  isActive={toggleSwitch.isLossLimitation}
                  names="isLossLimitation"
                  handleClick={handleClick}
                />
              </div>
              <div className={`${styles.formLineFull}`}>
                <span className={styles.inputLabel}>
                  Federal Revenue Spread*
                </span>
                <CustomSwitch
                  isActive={toggleSwitch.isFederalRevenueSpread}
                  names="isFederalRevenueSpread"
                  handleClick={handleClick}
                />
              </div>
              <div
                className={`${
                  !toggleSwitch.isFederalRevenueSpread ? "!hidden" : ""
                } ${styles.formLineFull}`}
              >
                <label
                  htmlFor="monthlyPlacedInService"
                  className={styles.inputLabel}
                >
                  Month Placed In Service:
                </label>
                <Controller
                  name="monthlyPlacedInService"
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      id="monthlyPlacedInService"
                      className={styles.controller}
                    >
                      {months.map((month, index) => (
                        <option key={index} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  )}
                />
                {errors.monthlyPlacedInService && (
                  <p>{errors.monthlyPlacedInService.message}</p>
                )}
              </div>
              <div className={`${styles.formLineFull}`}>
                <span className={styles.inputLabel}>
                  Federal Bonus Depreciation*
                </span>
                <CustomSwitch
                  isActive={toggleSwitch.isFederalBonusDepreciation}
                  names="isFederalBonusDepreciation"
                  handleClick={handleClick}
                />
              </div>
              <div
                className={`${styles.formLineFull} ${
                  !toggleSwitch.isFederalBonusDepreciation ? "!hidden" : ""
                }`}
              >
                <p className={styles.inputLabel}>
                  Federal Bonus Depreciation %
                </p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="federalBonusDepreciationPercentage"
                    className={styles.inputBox}
                    {...register("federalBonusDepreciationPercentage")}
                    onChange={onChange}
                  />
                  {errors.federalBonusDepreciationPercentage && (
                    <p className={styles.error}>
                      {errors.federalBonusDepreciationPercentage.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull}`}>
                <label htmlFor="stateSelector" className={styles.inputLabel}>
                  Select a State:
                </label>
                <Controller
                  name="stateSelector"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="stateSelector"
                      isLoading={loading}
                      className={`!p-0 ${styles.controller}`}
                      options={stateOptions}
                      // options={stateOptions as OptionsOrGroups<string, GroupBase<string>>}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        setInputFieldsOnStateChange(selectedOption);
                      }}
                    />
                  )}
                />
                {/* {errors.stateSelector && <p>{errors.stateSelector.message}</p>} */}
              </div>
              <div className={`${styles.formLineFull}`}>
                <p className={styles.inputLabel}>State Tax Rate</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="stateTaxRate"
                    className={styles.inputBox}
                    {...register("stateTaxRate")}
                    onChange={onChange}
                  />
                  {errors.stateTaxRate && (
                    <p className={styles.error}>
                      {errors.stateTaxRate.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull}`}>
                <p className={styles.inputLabel}>State Tax Rate Yr 7-20</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="stateTaxRateYr7to20"
                    className={styles.inputBox}
                    {...register("stateTaxRateYr7to20")}
                    onChange={onChange}
                  />
                  {errors.stateTaxRateYr7to20 && (
                    <p className={styles.error}>
                      {errors.stateTaxRateYr7to20.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull}`}>
                <p className={styles.inputLabel}>State PTE Payment %</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="statePTEPayment"
                    className={styles.inputBox}
                    {...register("statePTEPayment")}
                    onChange={onChange}
                  />
                  {errors.statePTEPayment && (
                    <p className={styles.error}>
                      {errors.statePTEPayment.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull}`}>
                <label
                  htmlFor="stateBonusDepreciation"
                  className={styles.inputLabel}
                >
                  State Bonus Depreciation
                </label>
                <Controller
                  name="stateBonusDepreciation"
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      id="stateBonusDepreciation"
                      className={styles.controller}
                      value={field.value === true ? "true" : "false"}
                      onChange={(e) => {
                        const booleanValue = e.target.value === "true";
                        onChange({
                          target: { name: field.name, value: booleanValue },
                        });
                        field.onChange(booleanValue);
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  )}
                />
                {errors.stateBonusDepreciation && (
                  <p>{errors.stateBonusDepreciation.message}</p>
                )}
              </div>
              <div className={`${styles.formLineFull}`}>
                <label
                  htmlFor="stateBonusDepreciationAsFED"
                  className={styles.inputLabel}
                >
                  State Depreciation Same As FED Basis
                </label>
                <Controller
                  name="stateBonusDepreciationAsFED"
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      id="stateBonusDepreciationAsFED"
                      className={styles.controller}
                      value={field.value === true ? "true" : "false"}
                      onChange={(e) => {
                        const booleanValue = e.target.value === "true";
                        onChange({
                          target: { name: field.name, value: booleanValue },
                        });
                        field.onChange(booleanValue);
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  )}
                />
                {errors.stateBonusDepreciationAsFED && (
                  <p>{errors.stateBonusDepreciationAsFED.message}</p>
                )}
              </div>
              <div className={`${styles.formLineFull}`}>
                <label
                  htmlFor="stateRevenueSpread"
                  className={styles.inputLabel}
                >
                  State Revenue Spread
                </label>
                <Controller
                  name="stateRevenueSpread"
                  control={control}
                  render={({ field }) => (
                    <select
                      {...field}
                      id="stateRevenueSpread"
                      className={styles.controller}
                      value={field.value === true ? "true" : "false"}
                      onChange={(e) => {
                        const booleanValue = e.target.value === "true";
                        onChange({
                          target: { name: field.name, value: booleanValue },
                        });
                        field.onChange(booleanValue);
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  )}
                />
                {errors.stateRevenueSpread && (
                  <p>{errors.stateRevenueSpread.message}</p>
                )}
              </div>
              {profromaUserDetails?.designation !== "External" && (
                <>
                  {" "}
                  <div className={`${styles.formLineFull}`}>
                    <p className={styles.inputLabel}>ITC %</p>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        id="ITCPercentage"
                        className={styles.inputBox}
                        {...register("ITCPercentage")}
                        onChange={onChange}
                      />
                      {errors.ITCPercentage && (
                        <p className={styles.error}>
                          {errors.ITCPercentage.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.formLineFull}`}>
                    <p className={styles.inputLabel}>Inception Dev Fee</p>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        id="inceptionDevFee"
                        className={styles.inputBox}
                        {...register("inceptionDevFee")}
                        onChange={onChange}
                      />
                      {errors.inceptionDevFee && (
                        <p className={styles.error}>
                          {errors.inceptionDevFee.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.formLineFull}`}>
                    <p className={styles.inputLabel}>Developer Dev Fee</p>
                    <div className={styles.inputContainer}>
                      <input
                        type="text"
                        id="developerDevFee"
                        className={styles.inputBox}
                        {...register("developerDevFee")}
                        onChange={onChange}
                      />
                      {errors.developerDevFee && (
                        <p className={styles.error}>
                          {errors.developerDevFee.message}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
              {isExternal && (
                <div className={`${styles.formLineFull}`}>
                  <p className={styles.inputLabel}>Partner Markup %</p>
                  <div className={styles.inputContainer}>
                    <input
                      type="text"
                      id="partnerMarkup"
                      className={styles.inputBox}
                      {...register("partnerMarkup")}
                      onChange={onChange}
                    />
                    {errors.partnerMarkup && (
                      <p className={styles.error}>
                        {errors.partnerMarkup.message}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className={`${styles.formLineFull}`}>
                <p className={styles.inputLabel}>Federal Marginal Tax Rate</p>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="federalMarginalTaxRate"
                    className={styles.inputBox}
                    {...register("federalMarginalTaxRate")}
                    onChange={onChange}
                  />
                  {errors.federalMarginalTaxRate && (
                    <p className={styles.error}>
                      {errors.federalMarginalTaxRate.message}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.formLineFull} !justify-center`}>
                <button
                  type="submit"
                  className={`relative cursor-pointer rounded ${styles.logoutButton}`}
                >
                  SUBMIT
                </button>
              </div>
            </>
          )}
        </form>
      )}
    </div>
  );
};

export default ProformaForm;
