import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IEPCUser {
  crmContactId: string;
  email: string;
  firstName: string;
  lastName: string;
  contactGroup: string;
  contactType: string;
}

const initialState: IEPCUser = {
  email: "",
  crmContactId: "",
  firstName: "",
  lastName: "",
  contactGroup: "",
  contactType: "",
};

const epcUserSlice = createSlice({
  name: "epc_user",
  initialState,
  reducers: {
    setEpcUser: (state, action: PayloadAction<IEPCUser>) => {
      state.email = action.payload.email;
      state.crmContactId = action.payload.crmContactId;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.contactGroup = action.payload.contactGroup;
      state.contactType = action.payload.contactType;
    },
    resetEpcUser: (state) => {
      state.email = "";
      state.crmContactId = "";
      state.firstName = "";
      state.lastName = "";
      state.contactGroup = "";
      state.contactType = "";
    },
  },
});

export const { setEpcUser, resetEpcUser } = epcUserSlice.actions;

export default epcUserSlice.reducer;
