import Loader from "../Loader";


export const Redirect = () => {


    const redirectToMainWebsite = () => {
        // window.parent.location.href = 'http://localhost:3000/epc/isoftpull';
        window.parent.location.href = 'https://inceptionfinancial.app/epc/isoftpull';
    };

    redirectToMainWebsite()

    return (
        <>
            <div className="h-screen w-screen grid place-items-center text-white bg-green-900">
                <p>
                    <Loader isLoading={true} />
                    <p className="mt-2">
                        Redirecting
                    </p>
                </p>
            </div>
        </>
    )
}