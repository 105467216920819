import { RootState, useAppDispatch } from "../../store/store";
import styles from "./index.module.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FolderImg from "../../assets/images/FolderLogo.png";
import DownloadLogo from "../../assets/images/DownloadIcon.png";
import PdfImg from "../../assets/images/PdfLogo.png";
import ResultImg1 from "../../assets/images/ResultSectionImg1.png";
import ResultImg2 from "../../assets/images/ResultSectionImg2.png";
import Logo from "../../assets/images/InceptionFinicallyLogo.png";
import config from "../../configs/env.development";
import moment from "moment-timezone";
import {
  useGetPastReportsQuery,
  useSubmitFormMutation,
  useSubmitEquityContributionMutation,
  useEntityInfoMutation,
  useGetFoldersByIdMutation,
  useGetFolderIdQuery,
  useGetEventDetailQuery,
  useGetApaDataQuery,
  useGetPurlFlowQuery,
} from "../../services/api";
import { setStage, updateFormData } from "../../store/reducers/stageReducer";
import Step3_1 from "../TableData";
import RunNewForma from "../RunNewForma";
import Loader from "../Loader";
import MyDocumentTile from "../MyDocumentTile";
import ReactPlayer from "react-player";
import Tile1 from "./tile1";
import Tile2 from "./tile2";
import Tile3 from "./tile3";
import Tile4 from "./tile4";
import Tile5 from "./tile5";
import InviteOthers from "./tile6";
import CustomButton from "../Button/CustomButton";
import { setSubmission, ReducerState } from "../../store/reducers/tileReducer";
import ZohoTile from "./ZohoTile";

export const formatNumber = (number: string) => {
  const numericValue = parseInt(number.replace(/[^0-9]/g, ""));
  if (!isNaN(numericValue)) {
    return "$" + numericValue.toLocaleString();
  }
  return "";
};

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("token") || "";
  const loginData = useSelector((state: RootState) => state.login);
  const stageData = useSelector((state: RootState) => state.stage);
  const { reducers: tiles } = useSelector((state: RootState) => state.tile);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loader, setLoader] = useState(false);
  const [showSignTile, setShowSignTile] = useState(false);

  const { data: folderData, refetch: refetchDocs } = useGetFolderIdQuery(
    undefined,
    {
      skip: !stageData.softCommitComplete,
    }
  );
  const { data: eventDetail, refetch: refetchEvent } = useGetEventDetailQuery();
  const { data: apaData, refetch: refetchApa } = useGetApaDataQuery({
    id: loginData.zohoCustomerId,
  });
  // console.log('apa data', apaData);

  const { data: reportData, refetch } = useGetPastReportsQuery({
    page: currentPage,
  });
  const [result, { isLoading }] = useSubmitFormMutation();
  const [equityResult, { isLoading: equityResultLoading }] =
    useEntityInfoMutation();
  const [equityContributionApi, { isLoading: equityContributionLoading }] =
    useSubmitEquityContributionMutation();
  const [documentMutate, { isLoading: loading }] = useGetFoldersByIdMutation();
  const { data: purlFlow } = useGetPurlFlowQuery();

  const lastName = loginData.lastName;
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [isEventTrigger, setIsEventTrigger] = useState(false);
  const [overlayUrl, setOverlayUrl] = useState("");
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayVisible1, setOverlayVisible1] = useState(false);
  const [overlayComponentData, setOverlayComponentData] = useState<any>(null);
  const [equityContribution, setEquityContribution] = useState("");
  const [equityContributionSubmit, setEquityContributionSubmit] = useState("");
  const [totalPaymentAmount, setTotalPaymentAmount] = useState("");
  const [table, setTable] = useState<FormResponse>();
  const [showAnimatedOverview, setShowAnimatedOverview] = useState(false);
  const [dashboardVideoUrl, setDashboardVideoUrl] = useState("");
  const [error, setError] = useState("");
  const [hideCard, setHideCard] = useState(false);
  const [gdriveId, setGdriveId] = useState("");
  const [scheduledDate, setScheduledDate] = useState<ScheduledDate>();
  const [timeZone, setTimeZone] = useState("");
  const [folderId, setFolderId] = useState<String>("");
  const [equityContributionLoader, setEquityContributionLoader] =
    useState(false);
  const [showEquityModal, setShowEquityModal] = useState(false);
  const [isScheduleDatePasses, setIsScheduleDatePasses] = useState(false);
  const [showEquityChart, setShowEquityChart] = useState(
    stageData.entityInformationCollected ?? false
  );
  const [isSoftCommitComplete, setIsSoftCommitComplete] = useState(
    stageData.softCommitComplete
  );
  const [time, setTime] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    entityName: "",
    entityType: "",
    stateOfIncorporation: "",
    businessTitle: "",
    mailingStreet: "",
    mailingSuite: "",
    mailingCity: "",
    mailingCountry: "United States",
    mailingState: "",
    mailingZip: "",
    // driverLicense: null,
  });
  const [tile3DownloadLoader, setTile3DownloadLoader] = useState(false);

  const handleEquityNoModal = () =>
    setShowEquityModal((showEquityModal) => !showEquityModal);

  const [documentFolder, setDocumentFolder] = useState<DocumentFolderType[]>(
    []
  );

  const getFolders = async () => {
    try {
      const GDriveId = folderData?.data?.GDriveID || "";
      setGdriveId(GDriveId);
      const payload = { id: GDriveId, nextPageToken: "" };
      const data = (await documentMutate(payload)) as Response<any>;

      setDocumentFolder(data.data?.data?.message?.files);
      setMessage(data.data?.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (folderData?.data) {
      setFolderId(folderData?.data?.GDriveID);
      getFolders();
    }
  }, [folderData?.data]);

  const handleSubmitView = async (values: any) => {
    try {
      const newValues = { ...values };
      newValues.equityContribution = 0;
      newValues.taxRate = values?.estFederalTaxRate.toString();
      newValues.stateId = values?.stateId._id;
      newValues.grossIncome =
        values.grossIncome == null ? 0 : values.grossIncome;
      const data = (await result(newValues)) as Response<FormResponse>;

      if (data.data?.success) {
        dispatch(updateFormData(data?.data?.data));
        openOverlay1(<Step3_1 />);
      }
    } catch (error) {
      console.error("Error handling form submission:", error);
    }
  };

  const handleProma = () => {
    openOverlay1(<RunNewForma setTable={setTable} setData={setData} />);
  };
  const getExcel = async (row: any) => {
    setTile3DownloadLoader(true);
    try {
      const response = await fetch(
        `${config.baseUrl}/api/user/download-report`,
        {
          method: "POST",
          body: JSON.stringify({ id: row?._id }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response || !response.body) {
        console.log("Something Went Wrong");
        return;
      }
      const contentLength = +response.headers.get("content-length");
      const reader = response.body.getReader();
      let receivedLength = 0;
      const chunks = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        receivedLength += value.length;
      }

      const newDate = moment(row?.createdAt).format("MM.DD.YYYY");
      const fileName =
        lastName + "-" + row?.inputId?.equalityContribution + "-" + newDate;

      const blob = new Blob(chunks);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Error downloading Excel file");
    } finally {
      setTile3DownloadLoader(false);
    }
  };
  const handleDownload = (row: any) => {
    getExcel(row);
  };

  const openOverlay1 = (componentData: any) => {
    setOverlayComponentData(componentData);
    setOverlayVisible1(true);
  };

  const closeOverlay1 = () => {
    setOverlayComponentData(null);
    setOverlayVisible1(false);
  };

  const openOverlay = (url: any) => {
    setOverlayUrl(url);
    setOverlayVisible(true);
    setLoader(true);
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
    setOverlayUrl("");
  };
  const openAnimatedOverview = (url: string) => {
    setShowAnimatedOverview(true);
    setDashboardVideoUrl(url);
  };

  useEffect(() => {
    refetch();
  }, [currentPage]);

  useEffect(() => {
    transformData();
  }, [reportData?.data?.data]);

  const transformData = () => {
    if (reportData?.data?.data) {
      const data = reportData?.data?.data;
      setData(data);
    }
  };
  // const handlePageChange = (selectedItem: { selected: number }) => {
  //   setCurrentPage(selectedItem.selected + 1);
  // };

  const toggleModal = () => {
    if (!isOpen) {
      setFormData({
        entityName: "",
        entityType: "",
        stateOfIncorporation: "",
        businessTitle: "",
        mailingStreet: "",
        mailingSuite: "",
        mailingCity: "",
        mailingCountry: "United States",
        mailingState: "",
        mailingZip: "",
        // driverLicense: null,
      });
    }
    // setIsOpen(!isOpen);
  };
  const [equityFormLoader, setEquityFormLoader] = useState(false);
  const handleSubmitEquity = async (e: any) => {
    e.preventDefault();
    try {
      const data = (await equityResult(formData)) as Response<any>;
      if (data.data?.success) {
        dispatch(
          setStage({
            ...stageData,
            entityInformationCollected: true,
          })
        );
        setShowEquityChart(true);
      }
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setEquityFormLoader(false);
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitEquityData = async () => {
    setEquityContributionLoader(true);
    try {
      if (parseInt(equityContribution) < 50000) {
        setError("APA Document Minimum is $50,000");
        return;
      }
      setError("");
      const body = {
        equityContribution: equityContribution,
      };
      const data = (await equityContributionApi(body)) as Response<any>;
      if (data.data?.success) {
        setIsSoftCommitComplete(true);
        setEquityContributionSubmit(equityContribution);
        dispatch(
          setStage({
            ...stageData,
            softCommitComplete: true,
          })
        );
        dispatch(setSubmission({ component: "tile6", isSubmitted: false }));
        dispatch(setSubmission({ component: "tile5", isSubmitted: true }));
        setShowSignTile(true);
      }
      refetchApa();
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    } finally {
      setEquityContributionLoader(false);
    }
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value.replace(/[^0-9.]/g, "");
    setEquityContribution(value);
  };

  const compareDates = (
    date1: string,
    date2: string,
    hours: number,
    currentHours: number
  ) => {
    const momentDate1 = moment(date1, "DD MMM YYYY");
    const momentDate2 = moment(date2, "DD MMM YYYY");
    if (momentDate1.isBefore(momentDate2)) setIsScheduleDatePasses(true);
    else if (momentDate1.isAfter(momentDate2)) setIsScheduleDatePasses(false);
    else if (hours <= currentHours) setIsScheduleDatePasses(true);
    else setIsScheduleDatePasses(false);
  };
  const formatDates = (date: string) => {
    const newDate = moment(date).format("DD MMM YYYY");
    return newDate;
  };

  const sortTiles = () => {
    const tilesCopy = [...tiles];
    return tilesCopy.sort((a, b) => {
      if (a.isSubmitted !== b.isSubmitted) {
        return a.isSubmitted ? 1 : -1;
      }
      return a.isSubmitted ? a.order - b.order : b.order - a.order;
    });
  };

  useEffect(() => {
    if (eventDetail?.data) {
      const { start_time, end_time } = eventDetail?.data;
      const startDate = formatDates(start_time);
      const endDate = formatDates(end_time);
      const endDateForTime = new Date(end_time);
      const hours = endDateForTime.getHours();
      const currentDate = formatDates(new Date().toString());
      compareDates(startDate, currentDate, hours, new Date().getHours());

      const time = moment(start_time).local().format("h:mm A");
      setTime(time);

      const timezone = moment.tz.guess();
      setTimeZone(timezone);
      setScheduledDate({
        start_date: startDate,
        end_date: endDate,
      });
    }
  }, [eventDetail]);

  useEffect(() => {
    refetchEvent();
  }, [isEventTrigger]);

  useEffect(() => {
    if (showSignTile) {
      refetchApa();
    }
  }, [showSignTile]);

  useEffect(() => {
    if (isSoftCommitComplete == true) {
      refetchDocs();
    }
  }, [isSoftCommitComplete]);
  const [newTile, setNewTile] = useState<ReducerState[]>();
  useEffect(() => {
    setNewTile(sortTiles());
  }, [tiles]);

  useEffect(() => {
    if (
      apaData?.data?.Total_APA_Amount &&
      apaData?.data?.Total_APA_Amount > 10000
    ) {
      const totalPayment = String(apaData?.data?.Total_Payment_Amount);
      const totalAPA = String(apaData?.data.Total_APA_Amount);
      setTotalPaymentAmount(totalPayment);
      setEquityContributionSubmit(totalAPA);
    }
    if (
      (apaData?.data.CAF_Signed &&
        apaData?.data.MNDA_Signed &&
        apaData?.data.OMA_Signed) ||
      (!apaData?.data.CAF_Signed &&
        !apaData?.data.MNDA_Signed &&
        !apaData?.data.OMA_Signed &&
        !apaData?.data.request_id)
    ) {
      dispatch(setSubmission({ component: "tile5", isSubmitted: false }));
    } else {
      dispatch(setSubmission({ component: "tile5", isSubmitted: true }));
    }
    if (
      (apaData?.data.CAF_Signed &&
        apaData?.data.MNDA_Signed &&
        apaData?.data.OMA_Signed) ||
      (apaData?.data.CAF_Signed && !apaData?.data.mnda_request_id)
    ) {
      dispatch(setSubmission({ component: "tile6", isSubmitted: true }));
    } else {
      dispatch(setSubmission({ component: "tile6", isSubmitted: false }));
    }
  }, [apaData]);

  console.log("apa", newTile);

  return (
    <div className={`${styles.content}`}>
      <div className={styles.navbar}>
        <div>
          <img className={styles.navbarLogo} src={Logo} alt="Logo" />
        </div>
      </div>
      <div
        className={`${styles.container} grid gap-x-3 gap-y-3 grid-cols-1 md:grid-cols-1 lg:grid-cols-3`}
      >
        <Tile1
          openOverlay={openOverlay}
          openAnimatedOverview={openAnimatedOverview}
          openOverlay1={openOverlay1}
        />

        {newTile?.map((tile) => {
          if (tile.component == "tile2") {
            return (
              <Tile2
                stageData={stageData}
                scheduledDate={scheduledDate}
                timeZone={timeZone}
                time={time}
                isScheduleDatePasses={isScheduleDatePasses}
                setIsEventTrigger={setIsEventTrigger}
                isEventTrigger={isEventTrigger}
              />
            );
          } else if (tile.component == "tile3") {
            if (purlFlow?.data[0].benefitCalculator)
              return (
                <Tile3
                  handleProma={handleProma}
                  handleDownload={handleDownload}
                  handleSubmitView={handleSubmitView}
                  lastName={lastName}
                  PdfImg={PdfImg}
                  data={data}
                  isLoading={isLoading || tile3DownloadLoader}
                />
              );
          } else if (tile.component == "tile5") {
            return (
              stageData.cpaCallComplete && (
                // (!apaData?.data?.request_id ||
                //   (apaData?.data?.CAF_Signed &&
                //     apaData?.data?.MNDA_Signed &&
                //     apaData?.data?.OMA_Signed)) &&
                <Tile5
                  handleSubmitEquity={handleSubmitEquity}
                  handleChange={handleChange}
                  toggleModal={toggleModal}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                  setIsSoftCommitComplete={setIsSoftCommitComplete}
                  isSoftCommitComplete={isSoftCommitComplete}
                  showEquityChart={showEquityChart}
                  equityContribution={equityContribution}
                  submitEquityData={submitEquityData}
                  equityResultLoading={equityResultLoading}
                  error={error}
                  formData={formData}
                  isLoading={isLoading}
                  handleInputChange={handleInputChange}
                  setShowEquityChart={setShowEquityChart}
                  equityContributionSubmit={equityContributionSubmit}
                  totalPaymentAmount={totalPaymentAmount}
                  equityContributionLoader={equityContributionLoader}
                  equityFormLoader={equityFormLoader}
                  setEquityFormLoader={setEquityFormLoader}
                  apaData={apaData?.data}
                />
              )
            );
          } else if (tile.component == "tile6") {
            return (
              (equityContributionLoader || apaData?.data?.request_id) && (
                // !(
                //   apaData?.data?.CAF_Signed &&
                //   apaData?.data?.MNDA_Signed &&
                //   apaData?.data?.OMA_Signed
                // ))) &&
                <ZohoTile
                  equityContributionLoader={equityContributionLoader}
                  zohoCustomerId={loginData.zohoCustomerId}
                  apaData={apaData?.data}
                />
              )
            );
          } else if (tile.component == "tile7") {
            return (
              stageData.softCommitComplete &&
              documentFolder.length > 0 && (
                <div className={`${styles.tile} ${styles.customTile}`}>
                  <h2 className={styles.heading}>My Documents</h2>
                  {/* {loading && (
                  <div className="h-[80%] flex items-center">
                    <Loader isLoading={loading} size={40} />
                  </div>
                )} */}
                  {/* {gdriveId && !documentFolder?.length && !loading && (
                  <div className="h-[80%] flex justify-center items-center bg-white rounded font-semibold">
                    {message}
                  </div>
                )} */}
                  {!loading && folderId && (
                    <div className="grid gap-x-12 gap-y-6 grid-cols-2 mt-6">
                      {documentFolder?.map((folder) => {
                        return (
                          <div
                            onClick={() =>
                              openOverlay1(
                                <MyDocumentTile
                                  folder={folder}
                                  type={folder.name}
                                />
                              )
                            }
                            className={`${styles.tile6Box} items-center`}
                          >
                            <img
                              className={styles.tile6Img}
                              src={FolderImg}
                              alt=""
                            />
                            <div className="flex p-3 items-center">
                              <img
                                className={styles.downloadlogo}
                                src={DownloadLogo}
                                alt=""
                              />
                              <h3 className={`${styles.tile6heading} mx-2 `}>
                                Access {folder?.name}
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )
            );
          } else if (tile.component == "tile4") {
            return <InviteOthers />;
          }
        })}

        <Tile4 />

        {showAnimatedOverview && (
          <div
            className={styles.overlay}
            onClick={() => setShowAnimatedOverview(false)}
          >
            <div
              className={styles.overlayContent}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="h-full w-full flex items-center justify-center relative mb-10">
                <ReactPlayer
                  url={dashboardVideoUrl}
                  controls={true}
                  width="100%"
                  height="100%"
                  style={{ position: "relative" }}
                />
              </div>
              <span
                className={styles.closeBtn}
                onClick={() => setShowAnimatedOverview(false)}
              >
                &times;
              </span>
            </div>
          </div>
        )}
        {/* <Tile2
          stageData={stageData}
          scheduledDate={scheduledDate}
          timeZone={timeZone}
          time={time}
          isScheduleDatePasses={isScheduleDatePasses}
          setIsEventTrigger={setIsEventTrigger}
          isEventTrigger={isEventTrigger}
        />
        <Tile3
          handleProma={handleProma}
          handleDownload={handleDownload}
          handleSubmitView={handleSubmitView}
          lastName={lastName}
          PdfImg={PdfImg}
          data={data}
          isLoading={isLoading || tile3DownloadLoader}
        />

        <Tile4 />

        {showAnimatedOverview && (
          <div
            className={styles.overlay}
            onClick={() => setShowAnimatedOverview(false)}
          >
            <div
              className={styles.overlayContent}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="h-full w-full flex items-center justify-center relative mb-10">
                <ReactPlayer
                  url={dashboardVideoUrl}
                  controls={true}
                  width="100%"
                  height="100%"
                  style={{ position: "relative" }}
                />
              </div>
              <span
                className={styles.closeBtn}
                onClick={() => setShowAnimatedOverview(false)}
              >
                &times;
              </span>
            </div>
          </div>
        )}

        {stageData?.cpaCallComplete && (
          <Tile5
            handleSubmitEquity={handleSubmitEquity}
            handleChange={handleChange}
            toggleModal={toggleModal}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            setIsSoftCommitComplete={setIsSoftCommitComplete}
            isSoftCommitComplete={isSoftCommitComplete}
            showEquityChart={showEquityChart}
            formatNumber={formatNumber}
            equityContribution={equityContribution}
            submitEquityData={submitEquityData}
            equityResultLoading={equityResultLoading}
            error={error}
            formData={formData}
            isLoading={isLoading}
            handleInputChange={handleInputChange}
            setShowEquityChart={setShowEquityChart}
            equityContributionSubmit={equityContributionSubmit}
            equityContributionLoader={equityContributionLoader}
            equityFormLoader={equityFormLoader}
            setEquityFormLoader={setEquityFormLoader}
          />
        )}

        {apaData?.data?.[0].PURL_APA && (
          <div className={`${styles.tile} ${styles.customTile}`}>
            <h2 className={styles.heading}>Sign My Documents</h2>
            <div className="mt-6 flex justify-center items-center bg-white h-[80%] rounded-sm">
              <CustomButton
                style={{ height: "40px", width: "15rem" }}
                buttonLabel="Sign Document"
                onClick={() => alert("Work in progress !!")}
              />
            </div>
          </div>
        )}

        {stageData.softCommitComplete && documentFolder.length > 0 && (
          <div className={`${styles.tile} ${styles.customTile}`}>
            <h2 className={styles.heading}>My Documents</h2>
            {/* {loading && (
              <div className="h-[80%] flex items-center">
                <Loader isLoading={loading} size={40} />
              </div>
            )} */}
        {/* {gdriveId && !documentFolder?.length && !loading && (
              <div className="h-[80%] flex justify-center items-center bg-white rounded font-semibold">
                {message}
              </div>
            )} */}
        {/* {!loading && folderId && (
          <div className="grid gap-x-12 gap-y-6 grid-cols-2 mt-6">
            {documentFolder?.map((folder) => {
              return (
                <div
                  onClick={() =>
                    openOverlay1(
                      <MyDocumentTile folder={folder} type={folder.name} />
                    )
                  }
                  className={`${styles.tile6Box} items-center`}
                >
                  <img className={styles.tile6Img} src={FolderImg} alt="" />
                  <div className="flex p-3 items-center">
                    <img
                      className={styles.downloadlogo}
                      src={DownloadLogo}
                      alt=""
                    />
                    <h3 className={`${styles.tile6heading} mx-2 `}>
                      Access {folder?.name}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        )} */}
      </div>

      {hideCard && (
        <div className={`${styles.tile} ${styles.customTile}`}>
          <h2 className={styles.heading}>Your Results</h2>
          <div className="grid gap-x-6 gap-y-6 grid-cols-2 mt-8">
            <div className={styles.tile8Box}>
              <h2 className={styles.tile8heading}>Environmental Impact</h2>
              <div className={styles.tile8Description}>
                <div>
                  <img className={styles.tile8Img} src={ResultImg1} alt="" />
                </div>
                <div className={styles.descp}>
                  <div className={styles.tile8head3}>CO2 Emission Saved</div>
                  <p className="text-sm">10,206.34</p>
                </div>
              </div>
              <div className={styles.tile8Description}>
                <div>
                  <img className={styles.tile8Img} src={ResultImg2} alt="" />
                </div>
                <div className={styles.descp}>
                  <div className={styles.tile8head3}>
                    Equivalent Trees Planted
                  </div>
                  <p className="text-sm">304.67</p>
                </div>
              </div>
            </div>
            <div className={styles.tile8Box}>
              <h2 className={styles.tile8heading}>Tax Savings</h2>
              <p className={styles.tile8head2}>$15,376</p>
            </div>
          </div>
        </div>
      )}

      {overlayVisible && (
        <div className={styles.overlay} onClick={closeOverlay}>
          <div
            className={styles.overlayContent}
            onClick={(e) => e.stopPropagation()}
          >
            {loader && (
              <div className="absolute inset-0 flex items-center justify-center">
                <svg
                  aria-hidden="true"
                  className="w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#50b56e]"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            )}
            <iframe
              src={overlayUrl}
              frameBorder="0"
              className={styles.overlayIframe}
              onLoad={() => setLoader(false)}
            ></iframe>
            <span className={styles.closeBtn} onClick={closeOverlay}>
              &times;
            </span>
          </div>
        </div>
      )}

      {overlayVisible1 && (
        <div className={styles.overlay} onClick={closeOverlay1}>
          <div
            className={styles.overlayContent}
            onClick={(e) => e.stopPropagation()}
          >
            {overlayComponentData}
            <span className={styles.closeBtn} onClick={closeOverlay1}>
              &times;
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
