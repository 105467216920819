import pdfFile from '../../assets/pdf/IF_After20.pdf';


export const Pdf1 = () => {

    return (
        <>
            <iframe
                src={pdfFile}
                style={{ width: '100%', height: '100vh' }}
            />
        </>
    )
}