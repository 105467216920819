import pdfFile from '../../assets/pdf/QualifyingActivities.pdf'


export const Pdf6 = () => {

    return (
        <>
            <iframe
                src={pdfFile}
                style={{ width: '100%', height: '100vh' }}
            />
        </>
    )
}