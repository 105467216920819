import PpaStepline from "./PpaStepline";
import Step1 from "../Step1/Step1";
import { RootState, useAppSelector } from "../../../store/store";
import Step2 from "../Step2/Step2";
import Step3 from "../Step3/Step3";
import Step4 from "../Step4/Step4";
import Step5 from "../Step5/Step5";

const PpaCalculator = () => {
  const { step } = useAppSelector((state: RootState) => state.epc);
  let component;

  switch (step) {
    case 0:
      component = <></>;
      break;
    case 1:
      component = <Step1 />;
      break;
    case 2:
      component = <Step2 />;
      break;
    case 3:
      component = <Step3 />;
      break;
    case 4:
      component = <Step4 />;
      break;
    case 5:
      component = <Step5 />;
      break;
  }

  return (
    <div className="h-full flex flex-col items-center justify-center">
      {component}
      {/* {step > 0 && <PpaStepline />} */}
    </div>
  );
};

export default PpaCalculator;
