import React from "react";
import styles from "./steper.module.css";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "../../store/reducers/stepReducer";
interface Props {
  step: number;
  stepTitles: string[];
  onNavigateToStep?: (step: number) => void;
}
const Stepline = ({ onNavigateToStep, step, stepTitles }: Props) => {
  const dispatch = useDispatch();
  const { step: currentStep, completedStep } = useSelector(
    (state: any) => state.steps
  );
  console.log(completedStep, "completedstep");

  const stepData = [
    "",
    "VIDEO",
    "PRESENTATION",
    "BENEFITS CALCULATOR",
    "RESOURCE LIBRARY",
    `CPA CALL`,
  ];
  const totalSteps = 6;
  const range = (start: number, end: number) => {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  };

  const handleStepClick = (stepNumber: number) => {
    if (stepNumber <= completedStep) {
      if (onNavigateToStep) {
        onNavigateToStep(stepNumber);
        dispatch(setStep(stepNumber));
      }
    }
  };

  return (
    <div
      className={`${styles.container} ${
        step === 3
          ? "lg:w-[50vw] w-[90vw]"
          : "lg:w-[40vw] w-[100vw] px-6 lg:px-0"
      }`}
    >
      {range(2, totalSteps).map((item, index) => {
        const isCompleted = item <= completedStep;
        const isCurrent = item === currentStep;
        const isLastStep = index === totalSteps - 1;

        return (
          <div
            key={item}
            className={`${styles.container} ${
              isLastStep ? "" : styles.stepMargin
            } ${step === 3 ? "lg:w-[50vw] w-[90vw]" : "w-[40vw]"}`}
            onClick={() => handleStepClick(item)}
          >
            <div
              className={clsx(styles.step, {
                [styles.completed]: isCompleted,
                [styles.current]: isCurrent,
                [styles.pending]: item > currentStep,
              })}
            >
              <div
                className={
                  isCompleted
                    ? styles.innerCircle
                    : styles.innerCircleIncomplete
                }
              >
                {isCompleted ? (
                  <div className="h-20 mt-28 text-center lg:text-[13.5px] text-[10px]">
                    <span className={styles.stepData}>
                      {stepData[item - 1]}
                    </span>
                  </div>
                ) : (
                  <div className="h-20 mt-28 text-center w-[200px] lg:text-[13.5px] text-[10px]">
                    <span className={styles.stepDataIncomplete}>
                      {stepData[item - 1]}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {item < totalSteps && (
              <span
                className={clsx(styles.line, {
                  [styles.completedLine]: item <= completedStep,
                  [styles.pendingLine]: item > completedStep,
                })}
              ></span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Stepline;
