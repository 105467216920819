import styles from "./index.module.css";
import LoginForm from "../LoginForm";
import AgreementModal from "../AgreementModal";
import Dashboard from "../Dashboard";
import Step1 from "../Step1";
import Step2 from "../Step2";
import Step3 from "../Step3";
import { useAppSelector } from "../../store/store";
import { RootState } from "../../store/store";
import { useState } from "react";
import AlternateUser from "../AlternateUser";
import { useGetPurlFlowQuery } from "../../services/api";
import PurlDashboard from "../PurlDashboard";

interface Props {}

type Step = {
  step: number;
};
type Action = {
  type: string;
  payload?: number;
};

const reducer = (state: Step, action: Action) => {
  switch (action.type) {
    case "next":
      return { step: state.step + 1 };
    case "back":
      return { step: state.step - 1 };
    case "jump":
      return { step: action.payload ?? 0 };
    default:
      return { step: 0 };
  }
};

const BlackLayout = (props: Props) => {
  const {
    steps: { step },
  } = useAppSelector((state: RootState) => state);
  const { data: purlFlow } = useGetPurlFlowQuery();

  let component;
  let customClass;
  const [table, setTable] = useState<FormResponse>();

  const id = table?._id as string;
  if (step === 0) {
    customClass = "h-screen";
  } else if (step === 5 || step === 3 || step === 2 || step === 1) {
    customClass = "h-screen sm:min-h-screen";
  }

  const role =
    useAppSelector((state: RootState) => state.login.role) || "CUSTOMER";
  const roles = {
    customer: role == "CUSTOMER",
    advisor: role == "ADVISOR",
    cpa: role == "CPA",
    bookkeeper: role == "BOOKKEEPER",
  };

  if (roles.customer) {
    switch (step) {
      case 0:
        component = <LoginForm />;
        break;
      case 1:
        component = <AgreementModal />;
        break;
      case 2:
        component = <Step1 />;
        break;
      case 3:
        component = <Step2 />;
        break;
      case 4:
        component = <Step3 setTable={setTable} />;
        break;
      case 5:
        component = purlFlow?.data[0].dashboard ? (
          <PurlDashboard />
        ) : (
          <Dashboard />
        );
        break;
      default:
        component = <></>;
    }
  } else if (roles.advisor || roles.bookkeeper || roles.cpa) {
    switch (step) {
      case 0:
        component = <LoginForm />;
        break;
      case 1:
        component = <AlternateUser />;
        break;
      default:
        component = <></>;
    }
  }

  return (
    <div className={styles.customClass}>
      <div className={styles.content}>{component}</div>
    </div>
  );
};

export default BlackLayout;
