import { useState } from "react";
import Logo from "../../assets/images/InceptionFinicallyLogo.png";
import { HiOutlineMenu } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";

interface IProps {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar = ({ showSidebar, setShowSidebar }: IProps) => {
  return (
    <div className="flex w-full px-2 justify-between items-center bg-[#303545]">
      <div className=" flex justify-center py-2">
        <img src={Logo} width={120} height={4} alt="logo" />
      </div>
      <button onClick={() => setShowSidebar((prev) => !prev)}>
        {showSidebar ? (
          <IoMdClose color="white" fontSize={24} />
        ) : (
          <HiOutlineMenu color="white" fontSize={24} />
        )}
      </button>
    </div>
  );
};

export default Navbar;
