import { useLocation } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Pdf1 } from "../../components/ShowPdf/Pdf1";
import { Pdf2 } from "../../components/ShowPdf/Pdf2";
import { Pdf3 } from "../../components/ShowPdf/Pdf3";
import { Pdf4 } from "../../components/ShowPdf/Pdf4";
import { Pdf5 } from "../../components/ShowPdf/Pdf5";
import { Pdf6 } from "../../components/ShowPdf/Pdf6";

export const Document = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  let component;

  const updatedUrl = () => {
    switch (currentPath) {
      case "/IF_after20":
        component = <Pdf1 />;
        break;
      case "/IF_FMV_Calculation":
        component = <Pdf2 />;
        break;
      case "/IF_Performance_Report":
        component = <Pdf3 />;
        break;
      case "/3468InvestmentCreditTips":
        component = <Pdf4 />;
        break;
      case "/ExampleProductionReport":
        component = <Pdf5 />;
        break;
      case "/QualifyingActivitiesExample":
        component = <Pdf6 />;
        break;
      default:
        component = "not found";
        break;
    }
  };

  updatedUrl();

  return <>{component}</>;
};
