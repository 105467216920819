import { Controller, useForm } from "react-hook-form";
import styles from "./index.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { isoftSchema } from "../../yup";
import Select from "react-select";
import { useGetStatesQuery } from "../../services/api";
import { useEffect, useState } from "react";
import {
  useGetDashboardDataQuery,
  useIsoftPullFormMutation,
} from "../../services/epcApi";
import CustomButton from "../Button/CustomButton";
import { useAppDispatch } from "../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import Message from "./message";

interface State {
  _id: string;
  state: string;
}

interface StateOption {
  label: string;
  value: string;
}

interface IEPCUser {
  crmContactId: string;
  email: string;
  firstName: string;
  lastName: string;
  contactGroup: string;
  contactType: string;
}

export const IsoftForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(isoftSchema),
  });

  const { id } = useParams();
  const navigate = useNavigate();

  // const epcCrmContractId = useAppSelector(
  //   (state: RootState) => state.epcUser.crmContactId
  // );

  // const epcUserStr = localStorage.getItem("epcUserDetail");
  // let epcCrmId = "";
  // let epcUser: IEPCUser;
  // if (epcUserStr) {
  //   epcUser = JSON.parse(epcUserStr);
  //   epcCrmId = epcUser.crmContactId;
  // } else {
  //   epcCrmId = epcCrmContractId;
  // }

  const dispatch = useAppDispatch();
  const { data: states, isLoading, isSuccess } = useGetStatesQuery();
  const [submitForm, { isLoading: submitLoading }] = useIsoftPullFormMutation();
  // const { data: dashboardData } = useGetDashboardDataQuery(epcCrmId);
  const [stateOptions, setStateOptions] = useState<StateOption[]>([]);
  const [projectOpportunityId, setProjectOpportunityId] = useState("");

  const getStates = () => {
    const data = states?.data || [];
    const stateOption: StateOption[] = data.map((item: State) => {
      return {
        label: item.state,
        value: item._id,
      };
    });
    setStateOptions(stateOption);
  };

  const onSubmit = async (values: any) => {
    try {
      // const userData: any = await getDashboardData(epcCrmId)
      // const userData: any = await getDashboardData("4652598000068406170");
      // console.log("users", userData);
      // if (userData?.data.status != 200) {
      //   throw Error();
      // }
      // const arr = userData.data.response;
      // arr.sort((a: any, b: any) => (b.EPC_Projects.CREATEDTIME - a.EPC_Projects.CREATEDTIME))
      // console.log(arr);

      // const user = arr[arr.length - 1];
      // console.log(user, "uuu");
      const formData = values;
      // formData["crm_Project_Opportunity_ID"] = projectOpportunityId;
      formData["crm_Project_Opportunity_ID"] = "update-from-backend";
      formData["state"] = values.state.label;
      formData["token"] = id;
      const data: any = await submitForm(formData);
      console.log("isoft form ", data);
      if (data.data.success) {
        console.log("success");
        navigate("/epc/isoftpull/success");
      }
    } catch (error) {
      console.log(error);
      navigate("/epc/isoftpull/failed");
    }
  };

  // const fetchList = async () => {
  //   // const userData: any = await getDashboardData(epcCrmId);
  //   // const userData: any = await getDashboardData("4652598000068406170");
  //   if (dashboardData?.data.status != 200) {
  //     throw Error();
  //   }
  //   const arr = dashboardData?.data;
  //   const newArr = [...arr].sort((a: HostData, b: HostData) => {
  //     const timeA = new Date(a.EPC_Projects.MODIFIEDTIME).getTime();
  //     const timeB = new Date(b.EPC_Projects.MODIFIEDTIME).getTime();
  //     return timeA - timeB;
  //   });
  //   // console.log(newArr);
  //   const user = newArr[newArr.length - 1];
  //   setProjectOpportunityId(user.EPC_Projects.crm_Project_Opportunity_ID);
  //   dispatch(setEpcUser(epcUser));
  //   localStorage.removeItem("epcUserDetail");
  // };

  useEffect(() => {
    getStates();
  }, [states]);

  // useEffect(() => {
  //   dashboardData && fetchList();
  // }, [dashboardData]);

  // console.log('values', getValues());
  // console.log('err', errors);

  if (id == "success" || id == "failed") return <Message id={id} />;

  return (
    <>
      <div className="bg-white">
        <div className="text-center my-5">
          <div className="text-2xl">Get Prequalified Now!</div>
          <p className="lg:w-2/5 w-full m-auto text-center ">
            Want to learn what you may qualify for? Submit your information here
            and we will match you with the possible offerings without affecting
            your credit! Our software uses our soft credit check
            pre-qualification technology to match you! Eagle Dynamics LLC is a
            subsidiary of Inception Financial, a Solar PPA program
            administrator.
          </p>
        </div>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formContainer}>
            <div className={styles.row1}>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="firstName">
                  First Name*
                </label>
                <input
                  className={styles.inputs}
                  type="text"
                  id="firstName"
                  {...register("firstName")}
                />
                {errors.firstName && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.firstName.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="middleName">
                  Middle Name
                </label>
                <input
                  className={styles.inputs}
                  type="text"
                  id="middleName"
                  {...register("middleName")}
                />
              </div>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="lastName">
                  Last Name*
                </label>
                <input
                  className={styles.inputs}
                  type="text"
                  id="lastName"
                  {...register("lastName")}
                />
                {errors.lastName && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>

            <div className={styles.row1}>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="address">
                  Address*
                </label>
                <input
                  className={styles.inputs}
                  type="text"
                  id="address"
                  {...register("address")}
                />
                {errors.address && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.address.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="apt">
                  Apt/Suite/Other
                </label>
                <input
                  className={styles.inputs}
                  type="text"
                  id="apt"
                  {...register("apt")}
                />
              </div>
            </div>

            <div className="my-5"></div>

            <div className={styles.row1}>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="city">
                  City*
                </label>
                <input
                  className={styles.inputs}
                  type="text"
                  id="city"
                  {...register("city")}
                />
                {errors.city && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.city.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="state">
                  State*
                </label>
                {/* <input 
                                    className={styles.inputs}
                                    type="text"
                                    id="state"
                                    {...register('state')}
                                /> */}
                <Controller
                  name="state"
                  control={control}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      placeholder="Select State"
                      isLoading={isLoading}
                      options={stateOptions}
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption)
                      }
                    />
                  )}
                />
                {errors.state?.value && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.state.value.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="zip">
                  Zip*
                </label>
                <input
                  className={styles.inputs}
                  type="number"
                  id="zip"
                  {...register("zip")}
                />
                {errors.zip && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.zip.message}
                  </p>
                )}
              </div>
            </div>

            <div className={styles.row1}>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="socialSecurity">
                  Social Security
                </label>
                <input
                  className={styles.inputs}
                  type="number"
                  id="socialSecurity"
                  {...register("socialSecurity")}
                />
              </div>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="dob">
                  Date of Birth
                </label>
                <input
                  className={styles.inputs}
                  type="date"
                  id="dob"
                  {...register("dob")}
                />
              </div>
              <div className={styles.inputBox1}></div>
            </div>

            <div className={styles.row1}>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="phone">
                  Phone
                </label>
                <input
                  className={styles.inputs}
                  type="number"
                  id="phone"
                  {...register("phone")}
                />
              </div>
              <div className={styles.inputBox1}>
                <label className={styles.labels} htmlFor="email">
                  Email*
                </label>
                <input
                  className={styles.inputs}
                  type="text"
                  id="email"
                  {...register("email")}
                />
                {errors.email && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox1}></div>
            </div>

            <div className="my-5">
              <div className="flex mb-4">
                <input className="mx-4" type="checkbox" id="check1" />
                <label htmlFor="check1">
                  You understand that by clicking Submit immediately following
                  this notice, you are providing 'written instructions' under
                  the Fair Credit Reporting Act, authorizing Eagle Dynamics LLC
                  to obtain information from your personal credit profile and
                  other information from Equifax, TransUnion, and/or Experian to
                  conduct a pre-qualification for credit. This process will not
                  affect my credit score.
                </label>
              </div>
              <div className="flex mb-4">
                <input className="mx-4" type="checkbox" id="check2" />
                <label className="" htmlFor="check2">
                  I certify I have read and agree to the Electronic Disclosures
                  communications&nbsp;
                  <a
                    className="text-cyan-700"
                    href="https://app.isoftpull.com/site/usage_terms#e-consent"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </label>
              </div>
            </div>

            <div className="mb-8 text-center">
              {/* <button className="bg-blue-400 text-white rounded-md w-40 py-2 text-xl">
                Submit
              </button> */}
              <CustomButton buttonLabel="SUBMIT" isLoading={submitLoading} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
