import { RootState, useAppDispatch } from "../../store/store";
import { backStep, nextStep } from "../../store/reducers/stepReducer";
import styles from "./index.module.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetPurlFlowQuery, useUpdateStagesMutation } from "../../services/api";
import Stepper from "../Stepper";
import ReactPlayer from "react-player";
import { setStage } from "../../store/reducers/stageReducer";

const YouTubePlayIcon = ({ onClick, className }: any) => (
  <svg
    onClick={onClick}
    className={className}
    viewBox="0 0 68 48"
    width="68"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      cursor: "pointer",
      filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))",
    }}
  >
    <path
      className="ytp-large-play-button-bg"
      d="M.66,37.88a8,8,0,0,0,5.67,5.67C10.8,44.6,34,44.6,34,44.6s23.2,0,27.68-1.05a8,8,0,0,0,5.67-5.67A75.11,75.11,0,0,0,68,24,75.11,75.11,0,0,0,67.35,10.12a8,8,0,0,0-5.67-5.67C57.2,3.4,34,3.4,34,3.4s-23.2,0-27.68,1.05a8,8,0,0,0-5.67,5.67A75.11,75.11,0,0,0,0,24,75.11,75.11,0,0,0,.66,37.88Z"
      fill="#F2F3F4"
    />
    <path d="M45,24,27,14v20Z" fill="#757780" />
  </svg>
);
const SimpleNextIcon = ({ onClick, className }: any) => (
  <svg
    onClick={onClick}
    className={className}
    viewBox="0 0 24 24"
    width="60"
    height="60"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
  >
    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" fill="#fff" />
  </svg>
);
const Step1 = () => {
  const dispatch = useAppDispatch();
  const loginData = useSelector((state: RootState) => state.login);
  const stageData = useSelector((state: RootState) => state.stage);
  const {data: purlFlow} = useGetPurlFlowQuery()

  const [updateStage] = useUpdateStagesMutation();

  const zohoCustomerId = loginData.zohoCustomerId;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoEnded, setVideoEnded] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);

  const next = async () => {
    const newStateData = {
      ...stageData,
      videoComplete: true,
    };
    dispatch(setStage(newStateData));
    // const step = await setStepFunc(newStateData);
    dispatch(nextStep());
    await updateStage({ zohoId: zohoCustomerId, key: "videoComplete" });
  };

  const prev = () => {
    dispatch(backStep());
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    setVideoEnded(true);
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  const handleProgress = (state: any) => {
    setPlayedSeconds(state.playedSeconds);
  };
  const handleDuration = (dur: any) => {
    setDuration(dur);
  };

  useEffect(() => {
    if (playedSeconds + 1 >= duration && duration != 0) {
      setVideoEnded(true);
    }
  }, [playedSeconds, duration]);

  useEffect(() => {
    console.log('purlFlow?.data[0].video', purlFlow?.data[0].video);
    
    if(purlFlow && !purlFlow?.data[0].video)
      dispatch(nextStep())
  }, [purlFlow])

  return (
    <div className={`${styles.content}`}>
      <div className="relative flex flex-col items-center w-full">
        <div className="relative w-full lg:h-[70vh] h-[50vh] flex items-center justify-center">
          <ReactPlayer
            url="https://player.vimeo.com/video/949543563?h=1cc197bf6a&badge=0&autopause=0&amp;&player_id=0&app_id=58479"
            playing={isPlaying}
            controls={true}
            width="100%"
            height="100%"
            onProgress={handleProgress}
            onDuration={handleDuration}
            onEnded={handleVideoEnd}
            style={{ position: "relative" }}
          />
          {!isPlaying && isVideoEnded && (
            <YouTubePlayIcon
              className="absolute top-0 left-0 right-0 bottom-0 m-auto"
              onClick={togglePlay}
            />
          )}
          {isVideoEnded || stageData.videoComplete ? (
            <SimpleNextIcon
              onClick={next}
              className="absolute top-0 bottom-0 right-0 m-auto mr-12"
            />
          ) : (
            ""
          )}
        </div>
        <div className="w-full h-[20vh] flex flex-col lg:flex-row  items-center lg:justify-between justify-center lg:px-20 px-2">
          <div
            className={styles.videoheading}
            style={{ color: "white", fontFamily: "Signika", fontWeight: "500" }}
          >
            1. Watch Overview Video
          </div>
          <Stepper step={1} />
        </div>
      </div>
    </div>
  );
};
export default Step1;
