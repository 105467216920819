import pdfFile from '../../assets/pdf/IF_Production_Report.pdf'


export const Pdf5 = () => {

    return (
        <>
            <iframe
                src={pdfFile}
                style={{ width: '100%', height: '100vh' }}
            />
        </>
    )
}