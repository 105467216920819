import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./reducers/usersReducer";
import loginReducer from "./reducers/loginReducer";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { api } from "../services/api";
import stepReducer from "./reducers/stepReducer";
import stageReducer from "./reducers/stageReducer";
import epcReducer from "./reducers/epcReducer";
import epcUserReducer from "./reducers/epcUserReducer";
import { epcApi } from "../services/epcApi";
import tileReducer from "./reducers/tileReducer";
import proformaReducer from "./reducers/proformaReducer";
import { proformaApi } from "../services/proformaApi";

export const store = configureStore({
  reducer: {
    users: usersReducer,
    steps: stepReducer,
    login: loginReducer,
    stage: stageReducer,
    epc: epcReducer,
    epcUser: epcUserReducer,
    proformaUser: proformaReducer,
    tile: tileReducer,
    [api.reducerPath]: api.reducer,
    [epcApi.reducerPath]: epcApi.reducer,
    [proformaApi.reducerPath] : proformaApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, epcApi.middleware, proformaApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export interface StepState {
  step: number;
  completedStep: number;
}

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
