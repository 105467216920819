import { IColor } from "../EpcPopUp";

interface IProp {
  color: string;
  currentPage: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const Dots = ({ color, currentPage, page, setPage }: IProp) => {
  const updatePage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setPage(currentPage);
  };

  return (
    <button
      className={`h-3 w-3 rounded-full m-2 ${
        color == "accept"
          ? "bg-green-400"
          : color == "reject"
          ? "bg-red-400"
          : "bg-slate-400"
      } ${currentPage == page && "h-4 w-4"}`}
      onClick={updatePage}
    />
  );
};
