import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { EpcPopUp } from "./EpcPopUp";
import styles from "./index.module.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { HiSortAscending } from "react-icons/hi";
import { HiSortDescending } from "react-icons/hi";
interface Props {
  columns: {
    Header: string;
  }[];
  data: Array<HostData>;
  setPopup: React.Dispatch<React.SetStateAction<boolean>>;
  popup: boolean;
}

export interface HostData {
  EPC_Projects: EPCProject;
}
export interface EPCProject {
  Host_First_Name: string;
  Host_Last_Name: string;
  Host_Email: string;
  Project_Street_Address: string;
  Project_Country: string;
  Project_State: string;
  Project_Zip: string;
  Project_City: string;
  Project_Blended_Utility_Rate: string;
  Project_TurnKey_EPC_Price: string;
  MODIFIEDTIME: string;
  CREATEDTIME: string;
  Status: string;
  Project_Credit_Status: string;
  crm_Project_Opportunity_ID: string;
  Co_Host: string | null;
  Co_Host_First_Name: string;
  Co_Host_Last_Name: string;
  Co_Host_Email: string;
  Project_Opportunity_Doc_ID: string;
}

const EpcTable: React.FC<Props> = ({ columns, data, setPopup, popup }) => {
  const [popupData, setPopupData] = useState<EPCProject>();
  const [filteredData, setFilteredData] = useState(data);
  const [filterOrder, setFilterOrder] = useState<any>({
    "FIRST NAME": "asc",
    "LAST NAME": "asc",
    EMAIL: "asc",
    "STREET ADDRESS": "asc",
    "LAST UPDATED": "asc",
    CREDIT: "asc",
    STATUS: "asc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const showPopup = (row: HostData) => {
    setPopupData(row.EPC_Projects);
    setPopup(true);
  };

  const filterItems = (headerName: string, order: any) => {
    const currOrder = order == "asc" ? "dsc" : "asc";

    const check: any = {
      "FIRST NAME": "Host_First_Name",
      "LAST NAME": "Host_Last_Name",
      EMAIL: "Host_Email",
      "STREET ADDRESS": "Project_Street_Address",
      "LAST UPDATED": "MODIFIEDTIME",
      CREDIT: "Project_Credit_Status",
      STATUS: "Status",
    };
    if (!check[headerName]) {
      return;
    }
    setFilterOrder({ ...filterOrder, [headerName]: currOrder });

    const newData = [...filteredData].sort((a: any, b: any) => {
      const A = a.EPC_Projects[check[headerName]] ?? "";
      const B = b.EPC_Projects[check[headerName]] ?? "";
      if (A < B) return order === "asc" ? -1 : 1;
      if (A > B) return order === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredData(newData);
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < filteredData.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  let currentItems;
  if (filteredData?.length > 0) {
    const startIdx = (currentPage - 1) * itemsPerPage;
    currentItems = filteredData.slice(startIdx, startIdx + itemsPerPage);
  }
  useEffect(() => {
    setFilteredData(data);
    setCurrentPage(1);
  }, [data]);

  return (
    <>
      <table
        style={{
          borderSpacing: "0",
          width: "100%",
          backgroundColor: "none",
          marginTop: "0px",
          borderCollapse: "separate",
        }}
      >
        <thead style={{ position: "sticky", top: "0" }}>
          <tr>
            {columns.map((column) => (
              <th
                className="text-[#fff] w-[6rem] cursor-pointer sm:min-w-full min-w-48"
                key={column.Header}
                onClick={() =>
                  filterItems(column.Header, filterOrder[column.Header])
                }
                style={{
                  background: "none",
                  padding: "15px 0",
                  borderBottom: "none",
                  fontSize: "17px",
                }}
              >
                <div className="flex items-center justify-center ">
                  {column.Header}
                  {column.Header != "ACTION" &&
                    (filterOrder[column.Header] == "asc" ? (
                      <HiSortAscending className="text-lg ml-2" />
                    ) : (
                      <HiSortDescending className="text-lg ml-2" />
                    ))}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((row, rowIndex) => {
            const {
              Host_First_Name,
              Host_Last_Name,
              Host_Email,
              Project_State,
              Project_City,
              Project_Zip,
              Project_Street_Address,
              Status,
              Project_Credit_Status,
              MODIFIEDTIME,
            } = row?.EPC_Projects;
            const address =
              Project_Street_Address +
              (Project_Street_Address.includes(Project_City) ? "" : ", " + Project_City) +
              ", " +
              Project_State +
              ", " +
              Project_Zip;
            return (
              <tr
                key={rowIndex}
                className={`text-[#86b393] text-center text-sm w-[6rem] h-[5.5rem] ${"hover:bg-[#3B755E]"}`}
              >
                <td>{Host_First_Name}</td>
                <td>{Host_Last_Name}</td>
                <td>{Host_Email}</td>
                <td className="w-[9rem]">{address}</td>
                <td>{Project_Credit_Status ?? "-"}</td>
                <td>{Status == "" ? "-" : Status}</td>
                <td>{MODIFIEDTIME}</td>
                <td className="h-[auto]">
                  <button
                    onClick={() => showPopup(row)}
                    disabled={Status == "GEMS review"}
                  >
                    <FaEdit
                      className={`text-2xl ${
                        Status == "GEMS review" && `cursor-not-allowed`
                      }`}
                      // style={{ color: !pipeline ? "grey" : "" }}
                    />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {!currentItems && (
        <p className="text-white flex justify-center items-center text-3xl h-full ">
          No data available
        </p>
      )}
      <div className="absolute bottom-6 right-[10vw]  flex items-center justify-end">
        <button
          className={styles.paginate_btn}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <IoIosArrowBack />
        </button>
        <span className="text-white px-4">
          Page {currentPage} of {Math.ceil(filteredData.length / itemsPerPage)}
        </span>
        <button
          className={styles.paginate_btn}
          onClick={handleNextPage}
          disabled={currentPage * itemsPerPage >= filteredData.length}
        >
          <IoIosArrowForward />
        </button>
      </div>
      {popup && <EpcPopUp data={popupData} popup={popup} setPopup={setPopup} />}
    </>
  );
};

export default EpcTable;
