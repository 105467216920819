import styles from "./index.module.css";
import Button from "../ButtonV2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailSchema } from "../../yup";
import { useAppDispatch } from "../../store/store";
import { useEffect, useState } from "react";
import {
  useVerifyAccountMutation,
  useChangePinMutation,
} from "../../services/api";
import { useSearchParams } from "react-router-dom";
import { clsx } from "clsx";
import Modal from "../Modal";
import Heading from "../Heading";
import img from "../../assets/images/InceptionFinicallyLogo.png";

const ForgotPin = () => {
  const dispatch = useAppDispatch();
  const [result, { data, isLoading: loading }] = useVerifyAccountMutation();
  const [userDetails, setUserDetails] = useState<any>();
  const [mutate, { isLoading }] = useChangePinMutation();
  const [isError, setIsError] = useState("");
  const [pinError, setPinError] = useState("");
  const [searchParams] = useSearchParams();
  const url = window.location.href;
  const urlUniqueId = url.split("/").pop();
  const zohoId = searchParams.get("id") ?? "";
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const changePin = async (values: any) => {
    try {
      const data = (await mutate(values)) as Response<Pin>;
      if (data.error) {
        setPinError(data.error.data.message);
        setModalMessage(data.error.data.message);
        openModal();
        setTimeout(() => {
          setPinError("");
        }, 1500);
      } else {
        setModalMessage(data.data.message);
        openModal();
      }
    } catch (err: unknown) {
      openModal();
      console.error("Error:", err);
      setModalMessage("An error occurred.");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
  });

  const onSubmit = async (values: any) => {
    values.zohoId = userDetails.zohoCustomerId;
    changePin(values);
  };

  const changeScreen = () => {
    closeModal();
    window.location.href = ` ${
      urlUniqueId ? `/${urlUniqueId}` : `/dashboard?id=${zohoId}`
    } `;
  };

  const getResponse = async () => {
    try {
      if (!zohoId && !urlUniqueId) {
        setIsError("Invalid Url");
        return;
      }
      const payload: string = zohoId ? zohoId : urlUniqueId ? urlUniqueId : "";
      const data = (await result(payload)) as Response<VerifyResponse>;

      setUserDetails(data.data.data);
      console.log(data, "here is my data");
      if (data.error) {
        localStorage.clear();
        setIsError(data.error.data.message);
      }
    } catch (err: unknown) {}
  };
  useEffect(() => {
    getResponse();
  }, []);

  return (
    <div className={`${styles.content} min-h-screen h-screen`}>
      <div className=" flex justify-start px-10 pb-20 absolute top-0 left-0">
        <img src={img} alt="" className={`${styles.image}`} />
      </div>
      <div className="w-[30%] h-screen flex flex-col sm:flex-row items-center justify-center">
        {isError ? (
          <p className="text-red-500 text-[30px] sm:text-[35px] mb-[65px] w-[100%] font-bold text-center flex items-center justify-center">
            {isError}
          </p>
        ) : !loading ? (
          <div className="w-full ">
            <div className=" w-full">
              <p className="text-white font-normal text-md mb-2 font-raleway text-center sm:text-left">
                Forgot your PIN?
              </p>
              <div className="w-full">
                <div className="mb-20">
                  <form
                    className="flex w-full"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div
                      className={clsx(
                        styles.inputBox,
                        "flex items-center relative"
                      )}
                    >
                      <input
                        className={clsx(styles.input, "w-full font-raleway")}
                        placeholder="ENTER YOUR EMAIL ADDRESS"
                        {...register("email")}
                        name="email"
                        autoComplete="off"
                      />
                    </div>
                    <div className="flex justify-center md:block ">
                      <Button isLoading={isLoading} title="SUBMIT" />
                      {/* <Button title="Back" type="text" isLoading={isLoading} /> */}
                    </div>
                  </form>
                  {pinError && (
                    <p className="text-red-600 font-bold text-[15px] sm:text-[18px]">
                      {pinError}
                    </p>
                  )}
                  {errors.email && (
                    <p className="text-red-600 font-bold text-[15px] sm:text-[18px]">
                      {errors.email?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative w-full mb-10 inset-0 flex items-center justify-center">
            <svg
              aria-hidden="true"
              className="w-[60px] h-[60px] text-gray-200 animate-spin dark:text-gray-600 fill-[#55b76c]"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        )}
        <Modal
          show={showModal}
          onClose={() => closeModal()}
          classes="min-w-[25em] max-w-[30%]"
        >
          <div className={styles.modalContainer}>
            <div className={styles.modalText}>
              <Heading classes="leading-8" title={modalMessage} />
            </div>
          </div>
          <div className={styles.modalButtonContainer}>
            <Button onClick={changeScreen} title="OK" />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ForgotPin;
