import React, { useEffect, useState } from "react";
import { UserState } from "../../store/reducers/stageReducer";
import {
  useGetFolderIdQuery,
  useGetFoldersByIdMutation,
} from "../../services/api";
import MyDocumentTile from "../MyDocumentTile/index";
import FolderImg from "../../assets/images/FolderLogo.png";
import DownloadLogo from "../../assets/images/DownloadIcon.png";

interface Props {
  stageData: UserState;
  openOverlay1: (component: any) => void;
}

const Tile7 = ({ stageData, openOverlay1 }: Props) => {
  const { data: folderData } = useGetFolderIdQuery(undefined, {
    skip: !stageData.softCommitComplete,
  });
  // console.log(stageData, "sateagafua yast da");
  const [documentMutate, { isLoading: loading }] = useGetFoldersByIdMutation();

  const [documentFolder, setDocumentFolder] = useState<DocumentFolderType[]>(
    []
  );

  const getFolders = async () => {
    try {
      const GDriveId = folderData?.data?.GDriveID || "";
      //   setGdriveId(GDriveId);
      const payload = { id: GDriveId, nextPageToken: "" };
      const data = (await documentMutate(payload)) as Response<any>;

      setDocumentFolder(data?.data?.data?.message?.files);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (folderData?.data) {
      getFolders();
    }
  }, [folderData?.data]);

  if (documentFolder?.length <= 0) return null;

  return (
    <div>
      {stageData.softCommitComplete && documentFolder?.length > 0 && (
        <div
          className={`bg-[#FEFCFB] rounded-xl w-full pt-1 h-[390px] shadow-lg`}
        >
          <h2 className="text-[25px] mb-[8px] p-1 text-black font-normal">
            My Documents
          </h2>
          {!loading && folderData?.data?.GDriveID && (
            <div className="grid gap-x-12 gap-y-6 grid-cols-2 h-[70%] border-t-4 border-[#EFEFF4]">
              {documentFolder?.map((folder) => {
                return (
                  <div
                    onClick={() =>
                      openOverlay1(
                        <MyDocumentTile folder={folder} type={folder.name} />
                      )
                    }
                    className={`flex justify-center flex-col items-center cursor-pointer`}
                  >
                    <img className="w-[50px]" src={FolderImg} alt="" />
                    <div className="flex p-3 items-center">
                      <img
                        className="w-[30px] h-[30px]"
                        src={DownloadLogo}
                        alt=""
                      />
                      <h3 className={`text-[11px] mx-2 `}>
                        Access {folder?.name}
                      </h3>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tile7;
