import React, { useEffect, useState } from "react";
import CustomInput from "../../CustomInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { siteSchema, turnKeyPriceSchema } from "../../../yup";
import { colors } from "../../../utils/theme";
import CustomButton from "../../Button/CustomButton";
import { useDispatch } from "react-redux";
import {
  endEpcJourney,
  nextStep,
  backStep,
} from "../../../store/reducers/epcReducer";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

const Step4 = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(turnKeyPriceSchema),
  });
  const dispatch = useDispatch();
  const [monthlyPpaRate, setMonthlyPpaRate] = useState<string>("");
  const [ppaStatus, setPpaStatus] = useState<string>("");

  const goToNext = () => {
    dispatch(nextStep());
  };
  const goToPrev = () => {
    dispatch(backStep());
  };

  const onSubmit = (values: any) => {
    console.log(values);
    goToNext();
    reset();
  };

  useEffect(() => {
    setMonthlyPpaRate("0.300");
    setPpaStatus("valid");
    // setPpaStatus("invalid");
  }, []);

  return (
    <div className="h-[90%] w-1/2 flex flex-col items-start justify-center ">
      <p className={`text-[${colors.green}] font-normal text-xl`}>
        Enter the desired turn-key EPC price to calculate PPA rates.
      </p>
      <form className=" flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full mb-4 mt-2">
          <CustomInput
            placeholder="TURN-KEY EPC PRICE"
            name="turnKeyPrice"
            type="text"
            register={register}
            error={errors.turnKeyPrice}
          />
        </div>

        <div className=" bg-white  w-full relative flex gap-4 p-4 mb-4 items-center ">
          {ppaStatus.length !== 0 && (
            <div className="flex items-center justify-center absolute left-4 ">
              {ppaStatus === "valid" && (
                <div
                  className={`bg-[${colors.green}]  h-[80px] w-[80px] rounded-full flex items-center justify-center`}
                >
                  <FaCheck color="white" size={50} />
                </div>
              )}
              {ppaStatus === "invalid" && (
                <div
                  className={
                    "bg-red-600  h-[80px] w-[80px] rounded-full flex items-center justify-center"
                  }
                >
                  <RxCross2 color="white" size={50} />
                </div>
              )}
            </div>
          )}

          <div className="flex flex-col w-full gap-4 items-center">
            <p className="text-black font-semibold text-lg">MONTHLY PPA RATE</p>
            <div className=" flex gap-6 relative items-center justify-center ">
              {monthlyPpaRate.length === 0 && (
                <div className="flex items-center gap-3">
                  <div className=" w-4 border h-[6px] bg-black  "></div>
                  <div className=" w-[6px] h-[6px] absolute bottom-0 left-5 bg-black rounded-full "></div>
                  <div className=" w-8 border h-[6px] bg-black  "></div>
                </div>
              )}
              {monthlyPpaRate.length !== 0 && (
                <span className=" text-black font-extrabold text-3xl ">
                  {monthlyPpaRate}
                </span>
              )}
              <span className="text-black font-semibold">$/kWh</span>
            </div>
            <p className="text-black font-bold text-xl">
              25 years term & 2.9 % escalator
            </p>
          </div>
        </div>
        <div className="w-full gap-4 mt-12 flex flex-col items-center justify-center">
          <button
            type="button"
            onClick={goToPrev}
            style={{ backgroundColor: `${colors.green}` }}
            className={`cursor-pointer w-full py-2 text-lg  uppercase`}
          >
            Edit project data
          </button>
          <CustomButton
            buttonLabel="Accept & Continue"
            disabled={!monthlyPpaRate}
          />
        </div>
      </form>
      <button
        onClick={() => {
          dispatch(endEpcJourney());
        }}
        className={`underline self-center text-[${colors.green}] text-lg mt-5`}
      >
        Back to dashboard
      </button>
    </div>
  );
};

export default Step4;
