import { useNavigate } from "react-router-dom";
import img from "../../assets/images/InceptionFinicallyLogo.png";
import { colors } from "../../../src/utils/theme";
import CustomButton from "../../components/Button/CustomButton";
import CustomInput from "../../components/CustomInput";
import styles from "./index.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { proformaLoginSchema } from "../../yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import config from "../../../configs/env.development";
import { useDispatch } from "react-redux";
import { setEpcUser } from "../../store/reducers/epcUserReducer";
import { useLoginMutation } from "../../services/proformaApi";
import { setProformaUser } from "../../store/reducers/proformaReducer";

const LoginProforma = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(proformaLoginSchema),
  });

  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [login] = useLoginMutation();

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const result = await login(values).unwrap();
      if (result.success) {
        const userDetails: ProfromaDataResponse = {
          name: result.data.name,
          designation: result.data?.designation,
          inceptionDevFee: result.data?.inceptionDevFee,
          developerDevFee: result.data?.developerDevFee,
          itcPercent: result.data?.itcPercent,
          folderId: result.data?.folderId,
        };
        localStorage.setItem("token", result.data.token || "");
        dispatch(setProformaUser(userDetails));
        reset();
        setErrMsg("");
        navigate("/proforma");
      } else {
        throw new Error(`${result.message}`);
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrMsg("An error occurred during login.");
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setValue(name, value);
    setErrMsg("");
  };

  return (
    <div className={styles.customClass}>
      <div className={styles.content}>
        <div className="w-full flex items-center justify-center">
          <div className="w-[90vw] h-[90vh] relative flex flex-col items-center justify-center">
            <div className="flex justify-start px-10 pb-20 absolute top-0 left-0">
              <img src={img} alt="" className={`${styles.image}`} />
            </div>
            <form
              className="flex flex-col h-1/2 w-full md:w-[40%] items-center justify-center"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="w-[70%] mb-8">
                <CustomInput
                  placeholder="User Name"
                  name="name"
                  type="text"
                  register={register}
                  error={errors.name}
                  onChange={onChange}
                />
              </div>
              <div className="w-[70%] mb-2">
                <CustomInput
                  placeholder="Pin"
                  name="pin"
                  type="password"
                  register={register}
                  error={errors.pin}
                  onChange={onChange}
                />
              </div>
              <div className="flex flex-col items-center justify-center md:block w-[70%] mt-5">
                {errMsg && (
                  <p className="text-red-500 font-bold text-md mb-4">
                    {errMsg}
                  </p>
                )}
                <CustomButton buttonLabel="Login" isLoading={isLoading} />
              </div>
              {/* <a
                href={"/epc/forgot-password"}
                className={`text-[${colors.green}] font-normal underline w-1/2 flex items-center justify-center text-xl sm:text-[16px]`}
              >
                Forgot password?
              </a> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginProforma;
