import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ReducerState {
  isSubmitted: boolean;
  order: number;
  component: string;
  isActive: boolean;
}

interface AppState {
  reducers: ReducerState[];
}

const initialReducers: ReducerState[] = [
  { isSubmitted: false, order: 8, component: "tile1", isActive: false },
  { isSubmitted: false, order: 8, component: "tile2", isActive: false },
  { isSubmitted: true, order: 4, component: "tile3", isActive: false },
  { isSubmitted: true, order: 1, component: "tile4", isActive: false },
  { isSubmitted: false, order: 7, component: "tile5", isActive: false },
  { isSubmitted: true, order: 6, component: "tile6", isActive: false },
  { isSubmitted: true, order: 2, component: "tile7", isActive: false },
];

const initialState: AppState = {
  reducers: initialReducers,
};

const tileSlice = createSlice({
  name: "tile",
  initialState,
  reducers: {
    toggleSubmission: (state, action: PayloadAction<{ component: string }>) => {
      const { component } = action.payload;
      state.reducers = state.reducers.map((tile) =>
        tile.component === component
          ? { ...tile, isSubmitted: !tile.isSubmitted }
          : tile
      );
    },
    setSubmission: (
      state,
      action: PayloadAction<{ component: string; isSubmitted: boolean }>
    ) => {
      const { component, isSubmitted } = action.payload;
      state.reducers = state.reducers.map((tile) =>
        tile.component === component ? { ...tile, isSubmitted } : tile
      );
    },
    setActive: (state, action: PayloadAction<{ component: string }>) => {
      const { component } = action.payload;
      state.reducers = state.reducers.map((tile) =>
        tile.component === component
          ? { ...tile, isActive: true }
          : { ...tile, isActive: false }
      );
    },
    //reducer to update complete state
    setTiles: (state, action: PayloadAction<ReducerState[]>) => {
      state.reducers = action.payload;
    },

    resetState: (state) => {
      state.reducers = initialReducers;
    },
  },
});

// Export actions and selectors
export const {
  toggleSubmission,
  setTiles,
  resetState,
  setSubmission,
  setActive,
} = tileSlice.actions;

// Selector to get the entire reducers state
export const selectTileReducers = (state: { tile: AppState }) =>
  state.tile.reducers;

// Export the reducer to be used in the store
export default tileSlice.reducer;
