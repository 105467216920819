import React, { useCallback, useState } from "react";
import CustomButton from "../Button/CustomButton";
import styles from "./index.module.css";
import { useLazyGetZohoSignUrlQuery } from "../../services/api";

const ZohoTile = ({
  equityContributionLoader,
  zohoCustomerId,
  apaData,
}: {
  equityContributionLoader: boolean;
  zohoCustomerId: string;
  apaData?: IApaDoc;
}) => {
  const [popup, setPopup] = useState(false);
  const [zohoUrl, setZohoUrl] = useState("");

  const [trigger, { data, isLoading, isFetching, error }] =
    useLazyGetZohoSignUrlQuery();

  const handleGetZohoSign = async () => {
    try {
      const result = await trigger(zohoCustomerId).unwrap();
      console.log("resule", result);

      if (result.success) {
        setZohoUrl(result.data.output);
        setPopup(true);
        window.open(result.data.output, "_blank");
        // window.open(result.data.output, "_blank", "width=600,height=400,scrollbars=yes");
      } else {
        throw new Error("Failed to get Zoho sign URL");
      }
    } catch (err) {
      console.error("Error occurred while getting Zoho sign URL:", err);
    }
  };

  const onClose = () => {
    setPopup(false);
    setZohoUrl("");
  };
  return (
    <>
      <div className={`${styles.tile} ${styles.customTile}`}>
        {!equityContributionLoader && (
          <h2 className={styles.heading}>You have a document to sign.</h2>
        )}
        <div className="mt-6 flex justify-center items-center bg-white h-[80%] rounded-sm">
          {equityContributionLoader ? (
            <p className="text-2xl">Generating your documents...</p>
          ) : (apaData?.CAF_Signed && !apaData?.mnda_request_id) ||
            (apaData?.CAF_Signed &&
              apaData.MNDA_Signed &&
              apaData.OMA_Signed) ? (
            <p className="text-xl">Check back later for documents to sign.</p>
          ) : (
            <CustomButton
              style={{ height: "40px", width: "15rem" }}
              buttonLabel="Sign Document"
              onClick={handleGetZohoSign}
              disabled={isFetching}
              isLoading={isFetching}
            />
          )}
        </div>
      </div>

      {popup && false && (
        <div className={styles.overlay} onClick={onClose}>
          <div
            className={styles.overlayContent}
            onClick={(e) => e.stopPropagation()}
          >
            <span className={styles.closeBtn} onClick={onClose}>
              &times;
            </span>
            {zohoUrl ? (
              <iframe
                src={zohoUrl}
                height="500px"
                width="500px"
                className="border-4 border-t-emerald-400"
                title="Zoho Sign Document"
                // frame-ancestors='https://inceptionfinancial.app'
              >
                <p>Your browser does not support iframes.</p>
              </iframe>
            ) : (
              <p>Loading document...</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ZohoTile;
