import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/store";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useGetStatesQuery, useNewClientMutation } from "../../services/api";
import { newClientSchema } from "../../yup";
import CustomButton from "../Button/CustomButton";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/InceptionFinicallyLogo.png";

interface State {
  _id: string;
  state: string;
}
interface StateOption {
  label: string;
  value: string;
}

const SignUpForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(newClientSchema),
  });

  const [newClient, { isLoading: loading }] = useNewClientMutation();
  const { data: states, isLoading, isSuccess } = useGetStatesQuery();

  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [stateOptions, setStateOptions] = useState<StateOption[]>([]);

  const onSubmit = async (values: any) => {
    try {
      let income: string[] = [];
      values.sourceOfIncome.map((item: StateOption) => income.push(item.value));
      const sendData: INewClientReq = {
        First_Name: values.firstName,
        Last_Name: values.lastName,
        Email: values.email,
        Mobile: values.mobilePhone,
        State_of_Residence: values.state.label,
        Annual_Income: values.annualIncome.value,
        Primary_Source_of_Income: JSON.stringify(income),
        Is_an_Advisor: values.isAdvisor === "Yes",
        Contact_Type: "Client",
        Contact_Group: "Inception Financial",
      };

      const result = await newClient(sendData).unwrap();
      console.log("result", result);
      if (!result.success) {
        setSubmissionError(true);
      }
      if (result.success) {
        setShowConfirmationMessage(true);
      }
    } catch (err) {
      console.log("error", err);
      setSubmissionError(true);
    }
  };

  const getStates = () => {
    const data = states?.data || [];
    console.log(data);
    const stateOption: StateOption[] = data.map((item: State) => {
      return {
        label: item.state,
        value: item._id,
      };
    });
    setStateOptions(stateOption);
  };

  const incomeOptions = [
    { label: "$250,000 - $1,000,000", value: "$250,000 - $1,000,000" },
    { label: "$1,000,000 - $5,000,000", value: "$1,000,000 - $5,000,000" },
    { label: "$5,000,000 - $10,000,000", value: "$5,000,000 - $10,000,000" },
    { label: "$10,000,000 +", value: "$10,000,000 +" },
  ];
  const sourceOfIncomeOptions = [
    { label: "W2 Income", value: "W2 Income" },
    { label: "Sale of Stocks/Bonds", value: "Sale of Stocks/Bonds" },
    {
      label: "Sale of Company/Equity Event",
      value: "Sale of Company/Equity Event",
    },
    { label: "Other", value: "Other" },
  ];

  useEffect(() => {
    if (showConfirmationMessage) {
      const timer = setTimeout(() => {
        window.location.replace("https://www.inception.financial/");
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [showConfirmationMessage]);

  useEffect(() => {
    isSuccess && getStates();
  }, [states]);

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className=" flex justify-start px-10 pb-20 absolute top-0 left-0">
          <a href="https://www.inception.financial/">
            <img src={img} alt="" className={styles.image} />
          </a>
        </div>
        <div className={styles.signupForm}>
          {!showConfirmationMessage && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.inputBox}>
                <div className={styles.inputRow}>
                  <h2>Consult with Us</h2>
                </div>
                <div className={styles.inputRow}>
                  <div className="w-full mx-2">
                    <input
                      placeholder="FIRST NAME"
                      type="text"
                      className={styles.allInput}
                      {...register("firstName")}
                      name="firstName"
                      autoComplete="off"
                    />
                    {errors.firstName && (
                      <p className="text-red-500 text-[14px] text-left">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className="w-full mx-2">
                    <input
                      placeholder="LAST NAME"
                      type="text"
                      className={styles.allInput}
                      {...register("lastName")}
                      name="lastName"
                      autoComplete="off"
                    />
                    {errors.lastName && (
                      <p className="text-red-500 text-[14px] text-left">
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.inputRow}>
                  <div className="w-full mx-2">
                    <input
                      placeholder="MOBILE PHONE"
                      type="string"
                      className={styles.allInput}
                      {...register("mobilePhone")}
                      name="mobilePhone"
                      autoComplete="off"
                    />
                    {errors.mobilePhone && (
                      <p className="text-red-500 text-[14px] text-left">
                        {errors.mobilePhone.message}
                      </p>
                    )}
                  </div>
                  <div className="w-full mx-2">
                    <input
                      placeholder="EMAIL ADDRESS"
                      type="email"
                      className={styles.allInput}
                      {...register("email")}
                      name="email"
                      autoComplete="off"
                    />
                    {errors.email && (
                      <p className="text-red-500 text-[14px] text-left">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.inputRow}>
                  <div className="w-full mx-2">
                    <Controller
                      name="state"
                      control={control}
                      render={({ field }: any) => (
                        <Select
                          {...field}
                          className={`${styles.selectInput} `}
                          classNamePrefix="addl-class"
                          placeholder="STATE OF RESIDENCE"
                          isLoading={isLoading}
                          options={stateOptions}
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption)
                          }
                        />
                      )}
                    />
                    {errors.state && (
                      <p className="text-red-500 text-[14px] text-left">
                        {errors.state.message}
                      </p>
                    )}
                  </div>
                  <div className="w-full mx-2">
                    <Controller
                      name="annualIncome"
                      control={control}
                      render={({ field }: any) => (
                        <Select
                          {...field}
                          className={`${styles.selectInput} `}
                          classNamePrefix="addl-class"
                          placeholder="ANNUAL INCOME"
                          isLoading={isLoading}
                          options={incomeOptions}
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption)
                          }
                        />
                      )}
                    />
                    {errors.annualIncome && (
                      <p className="text-red-500 text-[14px] text-left">
                        {errors.annualIncome.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.inputRow}>
                  <div className="w-full mx-2">
                    <Controller
                      name="sourceOfIncome"
                      control={control}
                      render={({ field }: any) => (
                        <Select
                          {...field}
                          className={`${styles.selectInput} `}
                          classNamePrefix="addl-class"
                          isLoading={isLoading}
                          placeholder="PRIMARY SOURCE OF INCOME"
                          options={sourceOfIncomeOptions}
                          isMulti
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption)
                          }
                        />
                      )}
                    />
                    {errors.sourceOfIncome && (
                      <p className="text-red-500 text-[14px] text-left">
                        {errors.sourceOfIncome.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.inputRow}>
                  <div className={styles.advisorContainer}>
                    <p>I am an advisor or affiliated with an advisory firm:</p>
                    <div className={styles.radioButtonGroup}>
                      <input
                        type="radio"
                        id="Yes"
                        {...register("isAdvisor")}
                        name="isAdvisor"
                        value="Yes"
                      />
                      <label htmlFor="Yes">Yes</label>
                      <input
                        type="radio"
                        {...register("isAdvisor")}
                        id="No"
                        value="No"
                        name="isAdvisor"
                      />
                      <label htmlFor="No">No</label>
                    </div>
                    {errors.isAdvisor && (
                      <p className="text-red-500 text-[14px] text-left">
                        {errors.isAdvisor.message}
                      </p>
                    )}
                  </div>
                  <CustomButton
                    buttonLabel="SUBMIT"
                    isLoading={loading}
                    style={{ width: "160px", marginRight: "8px" }}
                  />
                </div>
              </div>
              {submissionError && (
                <div className="text-2xl text-red-500 text-center py-4">
                  Submission failed! Please try again
                </div>
              )}
            </form>
          )}
          {showConfirmationMessage && (
            <div className="w-full">
              <hr className="text-white" />
              <div className="text-3xl text-white leading-10 text-center py-6">
                A member of our client relations team will review your
                submission and you will hear from us if there is an opportunity
                to participate in the strategy.
              </div>
              <hr className="text-white" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
