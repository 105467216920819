import { useState } from "react";
import styles from "../index.module.css";
import Loader from "../../../Loader";
import { useUpdateNotesMutation } from "../../../../services/epcApi";

interface IProp {
  setNotePopup: React.Dispatch<React.SetStateAction<boolean>>;
  projectId?: string;
}

export const Note = ({ setNotePopup, projectId }: IProp) => {
  const [noteContent, setNoteContent] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [updateNotes, { isLoading: submitting }] = useUpdateNotesMutation();

  const handleBack = () => {
    setNotePopup(false);
  };

  const onSubmit = async () => {
    if (!noteContent.trim()) {
      setError("Note is required");
      setSuccess(false);
      return;
    }
    setIsLoading(true);
    try {
      console.log(noteContent);

      const sendData = {
        crm_Project_Opportunity_ID: projectId,
        notes: noteContent,
        // tile: 'title'
      };
      const result = await updateNotes(sendData);

      setSuccess(true);
      setNoteContent("");
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteContent(e.target.value);
    setError("");
    setSuccess(false);
  };

  return (
    <div className="w-full h-full">
      <p className="text-center text-black text-2xl">Notes</p>

      <div className="m-4 relative h-full">
        <textarea
          className="w-full h-36 border border-slate-500 p-2 mt-4 text-slate-800 rounded-md"
          placeholder="Add a note here"
          name="note"
          value={noteContent}
          onChange={onChange}
          required
        />
        {error && <p className="text-red-800 text-sm">{error}</p>}
        {success && (
          <p className="text-green-800 text-sm">Form submitted successfully</p>
        )}
        <div className="flex justify-between md:px-10 absolute w-full bottom-0 pb-16">
          <button
            className="h-12 w-24 border-2 border-red-800 rounded-md font-bold text-red-800"
            type="button"
            onClick={handleBack}
          >
            BACK
          </button>
          <button
            className={`${styles.gemsNoteBtn} w-24 rounded-md font-bold text-white `}
            type="button"
            onClick={onSubmit}
          >
            {isLoading ? <Loader isLoading={true} size={20} /> : "SAVE"}
          </button>
        </div>
      </div>
    </div>
  );
};
