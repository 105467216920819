import { useAppDispatch } from "../../store/store";
import { backStep, nextStep, setStep } from "../../store/reducers/stepReducer";
import styles from "./index.module.css";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetPurlFlowQuery,
  useUpdateStagesMutation,
} from "../../services/api";
import Stepper from "../Stepper";
import ReactPlayer from "react-player";
import { setStage } from "../../store/reducers/stageReducer";

const SimpleNextIcon = ({ onClick, className }: any) => (
  <svg
    onClick={onClick}
    className={className}
    viewBox="0 0 24 24"
    width="60"
    height="60"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
  >
    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" fill="#fff" />
  </svg>
);

export const testFunc = () => {
  console.log("hi from iframe");
};

const BackIcon = ({ onClick, className }: any) => (
  <svg
    onClick={onClick}
    className={className}
    viewBox="0 0 24 24"
    width="60"
    height="60"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
  >
    <path d="M15 16.59L10.41 12 15 7.41 13.59 6l-6 6 6 6z" fill="#fff" />
  </svg>
);

const Step2 = () => {
  const videoRef: any = useRef();
  const dispatch = useAppDispatch();
  const loginData = useSelector((state: any) => state.login);
  const stageData = useSelector((state: any) => state.stage);
  const [updateStage] = useUpdateStagesMutation();
  const zohoCustomerId = loginData.zohoCustomerId;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoEnded, setVideoEnded] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const { data: purlFlow } = useGetPurlFlowQuery();

  const next = async () => {
    const newStateData = {
      ...stageData,
      presentationComplete: true,
    };
    dispatch(setStage(newStateData));
    dispatch(nextStep());
    const updatedStage = await updateStage({
      zohoId: zohoCustomerId,
      key: "presentationComplete",
    });
  };

  const prev = () => {
    dispatch(backStep());
  };

  const handleProgress = (state: any) => {
    setPlayedSeconds(state.playedSeconds);
  };
  const handleDuration = (dur: any) => {
    setDuration(dur);
  };
  const handleVideoEnd = () => {
    setIsPlaying(false);
    setIsVideoPlaying(false);
    setVideoEnded(true);
  };

  useEffect(() => {
    if (playedSeconds + 1 >= duration && duration != 0) {
      setVideoEnded(true);
    }
  }, [playedSeconds, duration]);

  // useEffect(() => {
  //   const handleMessage = (event: any) => {
  //     if (event.data === "closeIframe") {
  //       next();
  //     }
  //   };
  //   window.addEventListener("message", handleMessage);
  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  useEffect(() => {
    if (purlFlow && !purlFlow?.data[0].presentation) dispatch(nextStep());
  }, [purlFlow]);

  return (
    <div className={`${styles.content}`}>
      <div className="relative flex flex-col items-center w-full">
        <div className="lg:h-[70vh] h-[50vh] w-full flex items-center justify-center relative mb-10">
          <ReactPlayer
            url="https://player.vimeo.com/video/1001799796?h=92ddd84060&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            // url="https://player.vimeo.com/video/964897870?h=be0966cfd7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            playing={isVideoPlaying}
            controls={true}
            width="100%"
            height="100%"
            onProgress={handleProgress}
            onDuration={handleDuration}
            onEnded={handleVideoEnd}
            style={{ position: "relative" }}
          />
          {isVideoEnded || stageData.presentationComplete ? (
            <SimpleNextIcon
              onClick={next}
              className="absolute top-0 bottom-0 mr-[10%] right-0 m-auto z-20"
            />
          ) : (
            ""
          )}
          {/* <iframe src="InceptionProject/presentation.html" className="fixed w-full h-[100vh] top-0 z-10"></iframe> */}
          <BackIcon
            onClick={prev}
            className="absolute top-0 bottom-0 ml-[10%] left-0 m-auto z-20"
          />
        </div>

        <div className="w-full h-[20vh] flex flex-col lg:flex-row  items-center lg:justify-between justify-center lg:px-20 px-2">
          <div className="flex flex-col justify-start">
            <div
              className="video-heading"
              style={{
                fontSize: "2rem",
                color: "white",
                fontFamily: "Signika",
                fontWeight: "500",
              }}
            >
              2. Learn More About the Strategy
            </div>
            <p
              style={{
                color: "#E2E7E5",
                width: "80%",
                fontWeight: "800",
                fontFamily: "Raleway",
              }}
            >
              Next, walk through this short interactive presentation to learn
              more about how the strategy works, its benefits, exit options,
              risk mitigation, and more.
            </p>
          </div>
          <Stepper step={2} />
        </div>
      </div>
    </div>
  );
};

export default Step2;
