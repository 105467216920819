import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  _id?: string;
  zohoCustomerId: string;
  firstName: string;
  lastName: string;
  steps?: string;
  role?: string;
}

const initialState: UserState = {
  _id: "",
  zohoCustomerId: "",
  firstName: "",
  lastName: "",
  steps: "",
  role: "",
};

export const loginSlice = createSlice({
  name: "userLogin",
  initialState,
  reducers: {
    setUserLogin: (state, action: PayloadAction<UserState>) => {
      state._id = action.payload._id || "";
      state.zohoCustomerId = action.payload.zohoCustomerId || "";
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.steps = action.payload.steps || "";
      state.role = action.payload.role || "";
      return state;
    },
  },
});

export const { setUserLogin } = loginSlice.actions;

export default loginSlice.reducer;
