import { clsx } from "clsx";
import styles from "./index.module.css";
import { FieldError } from "react-hook-form";

interface Props {
  placeholder?: string;
  error?: FieldError;
  name: string;
  type: string;
  register?: any;
  value?: string;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | Promise<void>;
}

const CustomInput = (props: Props) => {
  const {
    placeholder,
    name,
    type,
    register,
    error,
    onChange,
    value,
    defaultValue,
  } = props;

  return (
    <div className={"flex flex-col items-center justify-center"}>
      <input
        {...register(name)}
        className={clsx(
          error ? styles.invalid : styles.input,
          `w-full px-4 outline-none h-[42px] font-raleway text-lg bg-white rounded`
        )}
        defaultValue={defaultValue}
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
        autoComplete="off"
        onChange={onChange}
      />
      {error && (
        <span className="text-red-500 text-sm w-[100%] mt-1  font-bold flex items-start justify-start capitalize">
          {error.message}
        </span>
      )}
    </div>
  );
};

export default CustomInput;
