import { useEffect, useState } from "react";
import { setStep } from "../store/reducers/stepReducer";
import { useAppDispatch } from "../store/store";

function useStep() {
  const dispatch = useAppDispatch();
  const setStepFunc = (stage: any) => {
    const checkStages = [
      "purlLoginComplete",
      "purlNdaChecked",
      "videoComplete",
      "presentationComplete",
      "benefitCalculatorComplete",
    ];

    let step = 0;
    for (const item of checkStages) {
      const isNdaChecked =
        item == "purlNdaChecked" &&
        stage["purlNdaChecked"] != "1001-01-01" &&
        stage["purlNdaChecked"] != "";

      if (stage[item] == true || isNdaChecked) {
        step += 1;
      } else break;
    }
    dispatch(setStep(step));
    return step;
  };

  return { setStepFunc };
}

export default useStep;
