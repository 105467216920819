import styles from "./index.module.css";
import { useRef, useState, useEffect } from "react";
import Input from "../Input";
import img from "../../assets/images/InceptionFinicallyLogo.png";
import { incomeSchema } from "../../yup";
import { useSubmitFormMutation, useGetStatesQuery } from "../../services/api";
import Button from "../ButtonV2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  setTable: React.Dispatch<React.SetStateAction<FormResponse | undefined>>;
  setData: React.Dispatch<React.SetStateAction<any | undefined>>;
};

export type Rerun = {
  _id: string;
  userId: {
    _id: string;
    zohoCustomerid: string;
  };
  inputId: {
    _id: string;
    equalityContribution: number;
    grossIncome: number;
    stateId: {
      _id: string;
      state: string;
    };
    estFederalTaxRate: number;
  };
  isEdited: boolean;
  isFavourite: boolean;
};

const RunNewForma = ({ setTable, setData }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(incomeSchema),
  });

  const [stateId, setStateId] = useState("");
  const { data } = useGetStatesQuery();
  const [result, { error, isLoading }] = useSubmitFormMutation();
  // const [result2, { error: error2, isLoading: isLoading2 }] =
  //   useRerunReportMutation();
  const [isError, setIsError] = useState("");
  const [states, setStates] = useState<Option[]>([]);
  const inputProps = { register };
  const newRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [field1, setField] = useState(0);
  const [field2, setField2] = useState(0);
  const [isCalculated, setIsCalculated] = useState(false);

  const onSubmit = async (values: any) => {
    setOpen(false);
    values.stateId = stateId;
    values.taxRate = values?.taxRate ? values.taxRate?.replace(/%/g, "") : 0;
    values.grossIncome =
      values.grossIncome == ""
        ? 0
        : parseInt(values?.grossIncome?.replace(/[$,]/g, ""));

    const data = (await result(values)) as Response<FormResponse>;
    if (data.data?.success) {
      const taxPayment = Math.round(
        (values.grossIncome * values.taxRate) / 100
      );
      console.log(data.data.data);
      setField(taxPayment);
      setField2(data.data.data.totalBenefit);
      setIsCalculated(true);
      setTable(data.data.data);
      const stateName = states.filter((item) => item.id === values.stateId);
      const newObj = {
        _id: data.data.data._id,
        inputId: {
          _id: data.data.data._id,
          estFederalTaxRate: values.taxRate,
          equalityContribution: data.data.data.equalityContribution,
          grossIncome: values.grossIncome,
          stateId: {
            _id: stateId,
            state: stateName[0].label,
          },
        },
      };
      setData((prev: any) => [...prev, newObj]);
      reset();
    } else {
      setIsError(data.error.data?.message || data.error.message);
    }
    // }
  };

  useEffect(() => {
    if (data) {
      const states = data?.data.map((value: any, index: any) => {
        return {
          label: value.state,
          value: index + 1,
          id: value._id,
        };
      });
      setStates(states);
    }
  }, [data]);
  const handleOutsideClick = (e: MouseEvent) => {
    if (newRef.current && !newRef.current.contains(e.target as Node)) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <div className={`${styles.content}`}>
      <div className={`${styles.imageContainer}`}>
        <img src={img} alt="" className={`${styles.image}`} />
      </div>
      <div className="">
        <div className="h-[75vh] pt-[3%] items-center justify-center lg:w-[45vw] w-[75vw] relative ">
          <div className="flex flex-col justify-start items-start">
            <div
              className="video-heading"
              style={{
                fontSize: "1.6rem",
                color: "white",
                fontWeight: "600",
                fontFamily: "Signika",
              }}
            >
              Calculate Your Potential Benefits
            </div>

            <p
              style={{
                color: "#E2E7E5",
                fontFamily: "Raleway",
                fontWeight: "800",
              }}
              className="my-2 text-lg"
            >
              Now, let’s look more closely at your tax scenario and calculate
              your potential benefits.
            </p>
          </div>

          <form className={styles.inputBox} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputRow}>
              <Input
                placeholder="ESTIMATED ANNUAL INCOME (AGI IF KNOWN)"
                error={
                  errors?.grossIncome?.message
                    ? errors.grossIncome.message.toString()
                    : undefined
                }
                name="grossIncome"
                {...inputProps}
                val={getValues("grossIncome")}
                isDollar={true}
                // setValue={setValue}
              />
            </div>
            <div className={`${styles.inputRow} gap-2`}>
              <Input
                placeholder="STATE OF RESIDENCE"
                // error={errors?.state?.message}
                error={
                  errors?.state?.message
                    ? errors.state.message.toString()
                    : undefined
                }
                name="state"
                type="select"
                items={states}
                setValue={setValue}
                setStateId={setStateId}
                setOpen={setOpen}
                open={open}
                clearErrors={clearErrors}
                val={getValues("state")}
                {...inputProps}
              />
              <Input
                placeholder="ESTIMATED FEDERAL TAX RATE"
                error={
                  errors?.taxRate?.message
                    ? errors.taxRate.message.toString()
                    : undefined
                }
                name="taxRate"
                {...inputProps}
                val={getValues("taxRate")}
                isPercent={true}
                getValues={getValues}
              />
            </div>
            <div className="flex justify-end">
              <Button
                isLoading={isLoading}
                type="button"
                title="ESTIMATE MY BENEFITS"
              />
            </div>
          </form>

          {isCalculated && (
            <div className="my-2">
              <div className="text-white my-2">
                We've calculated your benefits based on the inputs you provided
                above.
              </div>

              <div className="flex" style={{ backgroundColor: "#03261A" }}>
                <div className="flex-auto flex flex-col justify-center items-center border-solid border-white border-r-2 p-8 bg-white-black">
                  <div
                    className="text-white text-sm"
                    style={{ fontFamily: "Raleway", fontWeight: "800" }}
                  >
                    Estimated tax due this Year:
                  </div>
                  <div
                    className="text-white text-sm"
                    style={{
                      fontFamily: "Raleway",
                      fontWeight: "800",
                      fontSize: "20px",
                      color: "#77B991",
                      marginTop: "5px",
                    }}
                  >
                    {field1 === 0 ? "$X" : `$${field1.toLocaleString()}`}
                  </div>
                </div>
                <div className="flex-auto flex flex-col justify-center items-center border-solid py-2 px-3">
                  <div
                    className="text-white text-sm"
                    style={{ fontFamily: "Raleway", fontWeight: "800" }}
                  >
                    Estimated 6-year tax saving with our strategy:
                  </div>
                  <div
                    className="text-white text-sm"
                    style={{
                      fontFamily: "Raleway",
                      fontWeight: "800",
                      fontSize: "20px",
                      color: "#77B991",
                      marginTop: "5px",
                    }}
                  >
                    {field2 === 0 ? "$X" : ` $${field2.toLocaleString()}`}
                  </div>
                </div>
              </div>

              <div
                className="text-xs flex justify-center "
                style={{
                  fontFamily: "Raleway",
                  fontWeight: "800",
                  color: "#77B991",
                  marginTop: "5px",
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RunNewForma;
