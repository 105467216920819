import { useAppSelector } from "../../store/store";

const AlternateUser = () => {
  const role = useAppSelector((state) => state.login.role);

  return (
    <div className="m-4 flex text-center flex-col mx-auto">
      <h1 className="xl:text-3xl md:text-xl sm:text-xl text-xl text-white font-bold w-full font-raleway">
        WELCOME, <span className="text-[#50b56e]">{role}</span>
      </h1>
      <p className="xl:text-3xl md:text-xl sm:text-xl text-xl text-white font-raleway">
        We're so glad you're here
      </p>
    </div>
  );
};

export default AlternateUser;
