import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  videoComplete?: boolean;
  purlLoginComplete?: boolean;
  purlInviteSent?: boolean;
  presentationComplete?: boolean;
  benefitCalculatorComplete?: boolean;
  cpaCallComplete?: boolean;
  softCommitComplete?: boolean;
  entityInformationCollected?: boolean;
  purlNdaChecked?: string;
  zohoCustomerId?: string;
  formData?: Object;
}

type Props = {
  data?: {
    data?: {
      data?: FormResponse;
      message: string;
      success: boolean;
    };
  };
};

const initialState: UserState = {
  videoComplete: false,
  purlLoginComplete: false,
  purlInviteSent: false,
  presentationComplete: false,
  benefitCalculatorComplete: false,
  cpaCallComplete: false,
  softCommitComplete: false,
  entityInformationCollected: false,
  purlNdaChecked: "1001-01-01",
  zohoCustomerId: "",
  formData: {},
};

export const stageSlice = createSlice({
  name: "stage",
  initialState,
  reducers: {
    setStage: (state, action: PayloadAction<UserState>) => {
      const {
        videoComplete = false,
        purlLoginComplete = false,
        purlInviteSent = false,
        presentationComplete = false,
        benefitCalculatorComplete = false,
        cpaCallComplete = false,
        softCommitComplete = false,
        entityInformationCollected = false,
        purlNdaChecked = "1001-01-01",
      } = action.payload || {};

      Object.assign(state, {
        videoComplete,
        purlLoginComplete,
        purlInviteSent,
        presentationComplete,
        benefitCalculatorComplete,
        cpaCallComplete,
        softCommitComplete,
        entityInformationCollected,
        purlNdaChecked,
      });

      return state;
    },
    updateFormData: (state, action: PayloadAction<any>) => {
      state.formData = action.payload;
      return state;
    },
  },
});

export const { setStage, updateFormData } = stageSlice.actions;
export const selectFormData = (state: any) => state.stage?.formData;

export default stageSlice.reducer;
