import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../configs/env.development";

interface IUpdateStatus {
  crm_Project_Opportunity_ID: string;
  Co_Host: string;
  Status: string;
}

interface DeleteObj {
  crmProjectOpportunityId: string;
  key: string;
  attachmentId: string;
}

export const epcApi = createApi({
  reducerPath: "epcApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.baseUrl}/api/epc`,
    prepareHeaders: (headers) => {
      // headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<ApiResponse<IEPCResponseLogin>, IEPCLogin>({
      query: (body) => ({
        url: "epc-login",
        method: "POST",
        body: body,
      }),
    }),
    // getDashboardData: builder.query<ApiResponse<IDashboardData>, string>({
    getDashboardData: builder.query<ApiResponse<any>, string>({
      query: (id) => ({
        url: `/get-dashboard-data/${id}`,
      }),
    }),
    resetPassword: builder.mutation<ApiResponse<IResetPass>, { email: string }>(
      {
        query: (body) => ({
          url: "reset-password",
          method: "POST",
          body: body,
        }),
      }
    ),
    updatePassword: builder.mutation<void, IUpdate>({
      query: (body) => ({
        url: "update-password",
        method: "POST",
        body: body,
      }),
    }),
    addCohost: builder.mutation<
      ApiResponse<{ status: number }>,
      ICohostrequest
    >({
      query: (data) => ({
        url: `add-cohost`,
        method: "POST",
        body: data,
      }),
    }),
    isoftPullForm: builder.mutation<any, FormData>({
      query: (data) => ({
        url: `/post-isoftpull-form`,
        method: "POST",
        body: data,
      }),
    }),
    epcFileUpload: builder.mutation<ApiResponse<any>, FormData>({
      //not used
      query: (formData) => ({
        url: `/epc-file-upload`,
        method: "POST",
        body: formData,
      }),
    }),
    cohostFileUpload: builder.mutation<ApiResponse<any>, FormData>({
      query: (formData) => ({
        url: `/upload-file-contact`,
        method: "POST",
        body: formData,
      }),
    }),
    editUser: builder.mutation<ApiResponse<IEditUserRes>, IEditUserReq>({
      query: (data) => ({
        url: `edit-user`,
        method: "POST",
        body: data,
      }),
    }),
    getProjectIdData: builder.mutation<ApiResponse<void>, ISolarDataReq>({
      query: (data) => ({
        url: `get-project-data`,
        method: "POST",
        body: { data },
      }),
    }),
    updateNotes: builder.mutation<ApiResponse<void>, INoteReq>({
      query: (data) => ({
        url: `update-note`,
        method: "POST",
        body: data,
      }),
    }),
    getNotes: builder.query<ApiResponse<INoteRes[]>, string>({
      query: (crm_Project_Opportunity_ID) => ({
        url: `get-note/${crm_Project_Opportunity_ID}`,
        method: "GET",
      }),
    }),
    getAllUploads: builder.query<ApiResponse<UploadedFileRes[]>, string>({
      query: (Project_Opportunity_Docs_ID) => ({
        url: `get-uploaded-documents/${Project_Opportunity_Docs_ID}`,
        method: "GET",
      }),
    }),
    deleteDocument: builder.mutation<ApiResponse<IDeleteDocsRes>, IDeleteDocs>({
      query: (data) => ({
        url: `delete-document`,
        method: "DELETE",
        body: data,
      }),
    }),
    downloadDocument: builder.mutation<Blob, IDownloadDocReq>({
      query: (data) => ({
        url: `download-document`,
        method: "POST",
        body: data,
        responseType: "Blob",
        responseHandler: (response) => response.blob(),
      }),
    }),
    downloadDocumentFromContacts: builder.mutation<Blob, IViewDocReq>({
      query: (data) => ({
        url: `download-from-contacts`,
        method: "POST",
        body: data,
        responseType: "Blob",
        responseHandler: (response) => response.blob(),
      }),
    }),
    getHostData: builder.query<ApiResponse<IAddDocsInHost>, string>({
      query: (crm_Project_Opportunity_ID) => ({
        url: `get-host-data/${crm_Project_Opportunity_ID}`,
        method: "GET",
      }),
    }),
    updateStatus: builder.mutation<ApiResponse<IStatusResponse>, IUpdateStatus>(
      {
        query: (data) => ({
          url: `update-status`,
          method: "POST",
          body: data,
        }),
      }
    ),
    addDocument: builder.mutation<ApiResponse<IAddDocsInHost>, IAddDocument>({
      query: (data) => ({
        url: `add-document`,
        method: "POST",
        body: data,
      }),
    }),
    // getHostById: builder.query<any, string>({
    //   query: (id) => ({
    //     url: `get-host/${id}`,
    //   }),
    // }),
    getHostDocuments: builder.query<any, string>({
      //not used
      query: (id) => ({
        url: `get-host-documents/${id}`,
      }),
    }),
    deleteAttachments: builder.mutation<void, IDeleteAttachment>({
      query: (data) => ({
        url: `delete-attachments`,
        method: "DELETE",
        body: data,
      }),
    }),
    deleteHostDocs: builder.mutation<
      ApiResponse<IDeleteHostDocsResponse>,
      DeleteObj
    >({
      query: (data) => ({
        url: `delete-host-documents`,
        method: "DELETE",
        body: data,
      }),
    }),
    getGemsDashboardData: builder.query<ApiResponse<any>, void>({
      query: () => ({
        url: `/gems-dashboard-data`,
      }),
    }),
    getCohostFileName: builder.query<ApiResponse<{ name: string }>, string>({
      query: (id) => ({
        url: `/cohost-file/${id}`,
      }),
    }),
    updateCohost: builder.mutation<ApiResponse<{ code: string }>, any>({
      query: (data) => ({
        url: `update-cohost`,
        method: "POST",
        body: data,
      }),
    }),
    sendIsoftEmail: builder.mutation<ApiResponse<void>, IsoftEmailReq>({
      query: (data) => ({
        url: `send-isoft-email`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetDashboardDataQuery,
  useResetPasswordMutation,
  useUpdatePasswordMutation,
  useAddCohostMutation,
  useIsoftPullFormMutation,
  useEpcFileUploadMutation,
  useEditUserMutation,
  useGetProjectIdDataMutation,
  useUpdateNotesMutation,
  useGetNotesQuery,
  useGetAllUploadsQuery,
  useGetHostDataQuery,
  useDeleteDocumentMutation,
  useDownloadDocumentMutation,
  useDownloadDocumentFromContactsMutation,
  useUpdateStatusMutation,
  useAddDocumentMutation,
  // useGetHostByIdQuery,
  useGetHostDocumentsQuery,
  useDeleteAttachmentsMutation,
  useDeleteHostDocsMutation,
  useGetGemsDashboardDataQuery,
  useGetCohostFileNameQuery,
  useUpdateCohostMutation,
  useSendIsoftEmailMutation,
  useCohostFileUploadMutation,
} = epcApi;
