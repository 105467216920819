import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./CustomSwitch.css";

interface Props {
  isActive: boolean;
  names: string;
  handleClick: (v: string) => void;
}

export default function CustomSwitch(props: Props) {
  const { isActive, names, handleClick } = props;

  return (
    <div className={`${names === "isAmpVantageActive" ? "ampSwitch" : ""} custom-switch`}>
      <input
        type="checkbox"
        checked={isActive}
        id={names}
        readOnly
        onClick={() => handleClick(names)}
      />
      <label htmlFor={names} className="switch-label">
        <span className="switch-button"></span>
        <span className="switch-on">ON</span>
        <span className="switch-off">OFF</span>
      </label>
    </div>
  );
}
