import styles from "./index.module.css";
import Button from "../ButtonV2";
import moment from "moment-timezone";

interface Props {
  data: any;
  isLoading: boolean;
  lastName: string;
  PdfImg: string;
  handleSubmitView: (val: any) => void;
  handleDownload: (row: any) => void;
  handleProma: () => void;
}

const Tile3 = ({
  data,
  isLoading,
  lastName,
  PdfImg,
  handleSubmitView,
  handleDownload,
  handleProma,
}: Props) => {
  return (
    <div
      className={`${styles.tile} ${styles.customTile}`}
      style={{ overflow: "hidden" }}
    >
      <h2
        className={styles.heading}
        style={{ fontFamily: "Signika", height: "10%" }}
      >
        Pro Formas
      </h2>
      <div className="" style={{ height: "90%" }}>
        {isLoading ? (
          <h1 className="text-white flex justify-center mt-12">
            <Button isLoading={isLoading} title="" variant="light4" />
          </h1>
        ) : (
          <>
            <div className={styles.overflowContainer} style={{ height: "80%" }}>
              {data?.map((row: any, ind: number) => {
                const newDate = moment(row.createdAt).format("MM/DD/YYYY");
                return (
                  <div className={styles.title3Box} key={ind}>
                    <div className="flex items-center gap-2">
                      <img className={styles.tile3Img} src={PdfImg} alt="" />
                      <p className={styles.tile3Descprition}>
                        <span>{lastName}</span>
                        {"-"}
                        <span>${row?.inputId?.equalityContribution}</span>
                        {"-"}
                        <span>{newDate}</span>
                      </p>
                    </div>
                    <div className={styles.description}></div>
                    <div className={styles.tile3Buttons}>
                      <button
                        className={`${styles.tile3Button} mx-2`}
                        title="VIEW"
                        onClick={() => {
                          handleSubmitView(row.inputId);
                        }}
                      >
                        VIEW
                      </button>
                      <button
                        onClick={() => handleDownload(row)}
                        className={styles.tile3Button}
                      >
                        DOWNLOAD
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end">
              <button
                className={styles.tile4Button}
                onClick={handleProma}
                style={{ height: "20%" }}
              >
                RUN A NEW PRO FORMA
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Tile3;
