import React from 'react'
import { useNavigate } from 'react-router-dom'

const Message = ({id}: {id: string}) => {

    const navigate = useNavigate()

    return (
        <div className='h-screen text-center content-center'>
            <div className='flex flex-col items-center m-auto p-8 rounded-xl border border-gray-500 md:w-4/12'>
                <div className='text-2xl my-8 '>
                    {id=='success'
                    ? 'Thank you, your response has been submitted.'
                    : 'Failed to submit the form'
                }
                </div>
                <button className='w-44 h-12 bg-green-800 text-white rounded-md' onClick={() => navigate('/epc/dashboard')}>
                    Back to dashboard
                </button>
            </div>
        </div>
    )
}

export default Message