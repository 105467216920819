import { createSlice } from "@reduxjs/toolkit";

interface IStep {
  step: number;
}

const initialState: IStep = {
  step: 0,
};

const epcSlice = createSlice({
  name: "EPC",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.step = state.step + 1;
    },
    backStep: (state) => {
      state.step = state.step - 1;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    endEpcJourney: (state) => {
      state.step = 0;
    },
  },
});
export const selectEpcStep = (state: IStep) => state.step;
export const { nextStep, backStep, setStep, endEpcJourney } = epcSlice.actions;

export default epcSlice.reducer;
