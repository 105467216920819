import CustomButton from "../Button/CustomButton";
import styles from "./index.module.css";
const Tile4 = () => {
  return (
    <div
      className={`${styles.tile} ${styles.customTile}`}
      style={{ overflow: "hidden" }}
    >
      <h2
        className={styles.heading}
        style={{ fontFamily: "Signika", height: "10%" }}
      >
        Provide Feedback on Your Portal Experience
      </h2>
      <div className="" style={{ height: "85%" }}>
        <div className="h-full flex justify-center items-center bg-white rounded">
          {/* <a
            href="https://zfrmz.com/fHcCGLMIBcMb6nFQr4yc"
            target="_blank"
            className={styles.tile4Button}
          >
            FEEDBACK FORM
          </a> */}
          <CustomButton
            style={{ height: "40px", width: "15rem" }}
            buttonLabel="Feedback form"
            onClick={() =>
              window.open("https://zfrmz.com/fHcCGLMIBcMb6nFQr4yc")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Tile4;
