import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import styles from "./index.module.css";
import { string, Schema } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader";
import { useGetStatesQuery } from "../../services/api";

const validationSchemas: { [key: string]: Schema<string> } = {
  entityName: string().required("Entity name is required"),
  entityType: string().required("Entity type is required"),
  stateOfIncorporation: string().required("State of incorporation is required"),
  businessTitle: string().required("Business title is required"),
  mailingStreet: string().required("Mailing street is required"),
  mailingCity: string().required("Mailing city is required"),
  mailingCountry: string().required("Mailing country is required"),
  mailingState: string().required("Mailing state is required"),
  mailingZip: string().required("Mailing zip is required"),
  // driverLicense: string().required("Driver License is required"),
};

interface FormFields {
  label: string;
  type: string;
  name: string;
  placeholder: string;
  options?: string[];
  tooltip?: string;
}

interface FormData {
  entityName: string;
  entityType: string;
  stateOfIncorporation: string;
  businessTitle: string;
  mailingStreet: string;
  mailingSuite: string;
  mailingCity: string;
  mailingCountry: string;
  mailingState: string;
  mailingZip: string;
  // driverLicense?: File | null;
  [key: string]: string | File | null | undefined;
}

interface EquityModalProps {
  isOpen: boolean;
  onClose: () => void;
  formData: FormData;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  toggleModal: () => void;
  equityFormLoader: boolean;
  setEquityFormLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const formFields: FormFields[] = [
  {
    label: "Entity Name",
    type: "text",
    name: "entityName",
    placeholder: "Enter entity name",
    tooltip:
      "Please enter full legal name for your entity as it would do business",
  },
  {
    label: "Entity Type",
    type: "select",
    name: "entityType",
    placeholder: "Select entity type",
    options: [
      "LLC",
      "Individual",
      "S-Corp",
      "C-Corp",
      "Profit Corporation",
      "Other",
      "Not Applicable ",
    ],
  },
  {
    label: "State of Incorporation",
    type: "select",
    name: "stateOfIncorporation",
    placeholder: "Select state of incorporation",
    options: ["State 1", "State 2", "State 3"],
  },
  {
    label: "Business Title",
    type: "text",
    name: "businessTitle",
    placeholder: "Enter business title",
    tooltip:
      "What is your title in the business? Common entries are Owner, Managing Member and CEO",
  },
  {
    label: "Mailing Street",
    type: "text",
    name: "mailingStreet",
    placeholder: "Enter mailing street",
  },
  {
    label: "Mailing Suite",
    type: "text",
    name: "mailingSuite",
    placeholder: "Enter mailing suite",
  },

  {
    label: "Mailing city",
    type: "text",
    name: "mailingCity",
    placeholder: "Enter mailing city",
  },
  {
    label: "Mailing country",
    type: "text",
    name: "mailingCountry",
    placeholder: "Enter mailing country",
  },
  {
    label: "Mailing state",
    type: "select",
    name: "mailingState",
    placeholder: "Select mailing state",
    options: ["State 1", "State 2", "State 3"],
  },
  {
    label: "Mailing zip",
    type: "text",
    name: "mailingZip",
    placeholder: "Enter mailing zip",
  },
];

const labels = ["Entity Name", "Business Title"];

const EquityModal: React.FC<EquityModalProps> = ({
  isOpen,
  onClose,
  formData,
  handleChange,
  handleSubmit,
  toggleModal,
  equityFormLoader,
  setEquityFormLoader,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { data } = useGetStatesQuery();
  const [states, setStates] = useState<
    { label: string; value: string; id: number }[]
  >([]);

  useEffect(() => {
    if (data) {
      const states = data.data.map(
        (value: { state: string; _id: string }, index: number) => ({
          label: value.state,
          value: value._id,
          id: index + 1,
        })
      );
      setStates(states);
    }
  }, [data]);

  const [formDataState, setFormDataState] = useState<FormData>({
    entityName: "",
    entityType: "",
    stateOfIncorporation: "",
    businessTitle: "",
    mailingStreet: "",
    mailingSuite: "",
    mailingCity: "",
    mailingCountry: "United States",
    mailingState: "",
    mailingZip: "",
    // driverLicense: null,
  });

  useEffect(() => {
    if (!isOpen) {
      setFormSubmitted(false);
      setErrors({});
    }
  }, [isOpen]);

  const handleValidation = (name: keyof FormData, value: string) => {
    try {
      validationSchemas[name].validateSync(value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    } catch (error: any) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
    }
  };
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    name: string
  ) => {
    handleChange(e as ChangeEvent<HTMLInputElement>);
    if (formSubmitted) {
      let value: string | File | null = "";
      // if (e.target instanceof HTMLInputElement) {
      // if (name === "driverLicense") {
      //   value = e.target.files?.[0] || null;
      // } else {
      // value = e.target.value;
      // }
      // } else {
      value = e.target.value;
      // }
      handleValidation(name as keyof FormData, value as string);
    }
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEquityFormLoader(true);
    setLoading(true);
    setFormSubmitted(true);
    let hasErrors = false;
    const newErrors: { [key: string]: string | null } = {};

    for (const field in formData) {
      const value = formData[field];
      if (Object.prototype.hasOwnProperty.call(formData, field)) {
        if (formSubmitted && value !== undefined) {
          handleValidation(field as keyof FormData, value as string);
        }

        if (field === "mailingSuite") {
          continue;
        }

        if (!value || value === "") {
          newErrors[field] = `${field} is required`;
          hasErrors = true;
        } else if (errors[field]) {
          hasErrors = true;
        }
      }
    }
    // setTimeout(() => {
    if (!hasErrors) {
      toggleModal();
      handleSubmit(e);
    }
    // }, 6000);
    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
    setLoading(false);
  };

  const handleClose = () => {
    setFormSubmitted(false);
    onClose();
  };

  const handleMailingCountryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(e);
    setFormDataState((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  if (!isOpen) return null;
  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          {equityFormLoader ? (
            <Loader isLoading={true} />
          ) : (
            <form className={styles.formContainer} onSubmit={handleFormSubmit}>
              <div className={styles.header}>
                <h3 className={styles.modalTitle}>Equity Contribution Form</h3>
                <button className={styles.closeButton} onClick={onClose}>
                  &times;
                </button>
              </div>
              {formFields.map((input: FormFields, index: number) => (
                <div key={index}>
                  <div className={styles.inputGroup}>
                    <label className={styles.formLabel}>
                      {input.label}
                      {input.label !== "Mailing Suite" &&
                        !labels.includes(input.label) && (
                          <span
                            className={styles.requiredAsterisk}
                            style={{ color: "red" }}
                          >
                            *
                          </span>
                        )}
                      {labels.includes(input.label) && (
                        <>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className={styles.infoIcon}
                            // title={`Information about ${input.label}`}
                          />
                          <div className={styles.tooltip}>{input.tooltip}</div>
                        </>
                      )}
                    </label>
                    <div className="w-[70%] ">
                      {input.type === "select" ? (
                        <select
                          className={styles.selectBox}
                          name={input.name as string}
                          onChange={(e) => handleInputChange(e, input.name)}
                          value={formData[input.name] as string}
                        >
                          <option value="">{input.placeholder}</option>
                          {input.name === "stateOfIncorporation" ||
                          input.name === "mailingState"
                            ? states.map((state, index) => (
                                <option key={index} value={state?.value}>
                                  {state.label}
                                </option>
                              ))
                            : input.options &&
                              input.options.map((option, idx) => (
                                <option key={idx} value={option}>
                                  {option}
                                </option>
                              ))}
                        </select>
                      ) : (
                        <input
                          className={styles.inviteBox}
                          type={input.type}
                          name={input.name as string}
                          placeholder={input.placeholder}
                          onChange={(e) => handleInputChange(e, input.name)}
                          autoComplete="off"
                          {...{ value: formData[input.name] as string }}
                        />
                      )}
                    </div>
                  </div>
                  {errors[input.name] && formSubmitted && (
                    <div className={styles.errorMessage}>
                      {errors[input.name]}*
                    </div>
                  )}
                </div>
              ))}
              <div className="flex justify-end mt-2">
                <button className={styles.tileButtons} onClick={onClose}>
                  CLOSE
                </button>
                <button type="submit" className={styles.tileButton}>
                  SUBMIT
                </button>
                {/* <Button isLoading={isLoading} type="submit" title="SUBMIT" /> */}
                <div className="flex justify-end"></div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default EquityModal;
