import { useAppDispatch } from "../../store/store";
import { backStep } from "../../store/reducers/stepReducer";
import styles from "./index.module.css";
import { useRef, useState } from "react";
import img from "../../assets/images/InceptionFinicallyLogo.png";

const BackIcon = ({ onClick, className }: any) => (
  <svg
    onClick={onClick}
    className={className}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
  >
    <path d="M15 16.59L10.41 12 15 7.41 13.59 6l-6 6 6 6z" fill="#fff" />
  </svg>
);

const KnowledgeCenter = () => {
  const dispatch = useAppDispatch();
  const newRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const prev = () => {
    dispatch(backStep());
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (newRef.current && !newRef.current.contains(e.target as Node)) {
      setOpen(false);
    }
  };

  return (
    <div className={`${styles.content}`}>
      <div className={`${styles.imageContainer}`}>
        <img src={img} alt="" className={`${styles.image}`} />
      </div>
      <div className="">
        <div className="h-[75vh] pt-[1%] items-center justify-center w-[45vw] relative ">
          <div className="flex flex-col justify-start items-start">
            <div
              className="video-heading"
              style={{ fontSize: "2rem", color: "white", fontWeight: "600" }}
            >
              Knowledge Center
            </div>
            <p
              style={{ color: "#E2E7E5", fontSize: "1rem", fontWeight: "600" }}
              className="my-1.2 text-lg"
            >
              Our Knowledge Center provides answers to the questions you and
              your tax professional and other financial advisors may have.
            </p>
          </div>
          <div className="my-3">
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                How does the Solar Tax Strategy work?{" "}
              </a>
            </div>
            <div className="text-white text-sm my-1.5">
              <a className={styles.link} href="#">
                What is a Power Purchase Agreement (PPA)?
              </a>
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                The Solar Tax Strategy Process{" "}
              </a>
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                Determining How Many Assets to Purchase
              </a>
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                {" "}
                Working with Inception Financial
              </a>
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#"></a> The Tax Benefits
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                {" "}
                Tax Credit IRS Code References
              </a>
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                Depreciation
              </a>
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                Depreciation Tax Code Benefits
              </a>
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                Income Recognition - Federal
              </a>
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                Income Recognition -California
              </a>
            </div>
            <div className="text-white text-sm my-1.5 ">
              <a className={styles.link} href="#">
                {" "}
                Rules for Taxpayers
              </a>
            </div>
          </div>

          <div className="flex justify-between ">
            <div>
              <button className={`${styles.btn} flex w-[100px]`} onClick={prev}>
                <BackIcon className=" top-0 bottom-0 ml-[21%] left-0 m-auto" />
                <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeCenter;
