import Loader from "../Loader";
import { CSSProperties } from "react";
interface Props {
  buttonLabel: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: CSSProperties;
  isLoading?: boolean;
}
const CustomButton = (props: Props) => {
  const { buttonLabel, onClick, disabled, style = {}, isLoading } = props;
  return (
    <>
      <button
        onClick={onClick}
        disabled={isLoading}
        style={style}
        className={`relative h-12 bg-[#3B755E] cursor-pointer rounded ${
          disabled && "opacity-50"
        } w-full py-1`}
      >
        {isLoading ? (
          <Loader isLoading={true} />
        ) : (
          <span className=" text-white uppercase">{buttonLabel}</span>
        )}
      </button>
    </>
  );
};

export default CustomButton;
