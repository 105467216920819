import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { UseFormSetValue } from "react-hook-form";
import calender from "../../assets/images/Group.png";
import clsx from "clsx";
import { DownArrow } from "../../assets";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

type INPUT_TYPES = "textfield" | "date" | "select";

interface Props {
  placeholder?: string;
  labelText?: string;
  error?: string | undefined;
  type?: INPUT_TYPES;
  login?: boolean;
  name: string;
  register?: any;
  style?: Record<string, any>;
  className?: string;
  firstName?: string;
  items?: Option[];
  setValue?: UseFormSetValue<any>;
  setStateId?: React.Dispatch<React.SetStateAction<string>>;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
  clearErrors?: any;
  maxLength?: number;
  isPercent?: boolean;
  fixedValue?: string;
  val?: string | number;
  isDollar?: boolean;
  getValues?: any;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const Input = (props: Props) => {
  const {
    placeholder = "",
    labelText,
    error,
    register,
    login,
    className,
    firstName,
    type = "textfield",
    items = [],
    setValue,
    setStateId,
    setOpen,
    clearErrors,
    open,
    maxLength,
    isPercent,
    fixedValue,
    val,
    isDollar,
    getValues,
    name,
  } = props;

  const [calendarOpen, setCalenderOpen] = useState(false);
  const [reVal, setReVal] = useState(val);
  const [filteredItems, setFilteredItems] = useState<Option[]>(items);
  const [selected, setSelected] = useState<Option>();
  const [text, setText] = useState("");
  const [date, setDate] = useState("");
  const [labelShow, setLabelShow] = useState<any>("");

  useEffect(() => {
    if (isDollar === true && val) {
      let value = "$" + val?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setReVal(value);
    }
  }, []);

  const handleDropdown = (status: boolean) => {
    if (!text.trim().length) {
      setOpen?.(false);
    } else {
      setOpen?.(status);
    }
  };

  const onChangeHandler = (ev: any) => {
    if (ev.target.value === "") {
      setText("");
      reVal && setReVal("");
    } else if (
      props.name === "equityContribution" ||
      props.name === "grossIncome"
    ) {
      let value = ev.target.value;
      value = value.replace(/[$,]/g, "");
      value = "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setText(value);
      reVal && setReVal(value);
      props.register(props.name).onChange(ev);
      if (props.name === "equityContribution") setValues(ev);
    } else {
      setText(ev.target.value);
      reVal && setReVal(ev.target.value);
    }
    if (props.name === "taxRate") {
      ev.target.value === "" ? setText("") : handlePercentNumber(ev);
    }
    if (props.name === "grossIncome") {
      setValue?.("equityContribution", 0);
    }
  };

  const toggleDropdown = () => {
    if (open) {
      removeFilter();
    }
    setOpen?.(!open);
  };

  const handlePress = (e: any) => {
    const keyCode = e.keyCode;
    if (keyCode === 8 && props.name === "taxRate") {
      const value = text;
      const newVal = value.slice(0, value.length - 1);
      setText(newVal);
      setLabelShow(newVal);
    }
  };
  const removeFilter = () => {
    setFilteredItems(items);
  };
  useEffect(() => {
    if (text.trim().length) {
      handleDropdown(true);
      const filtered = filteredItems?.filter((item) =>
        item.label.toLowerCase().includes(text.toLowerCase())
      );
      if (filtered.length) {
        setFilteredItems(filtered);
      } else {
        handleDropdown(false);
      }
    } else {
      removeFilter();
      handleDropdown(false);
    }
  }, [text, items.length]);

  useEffect(() => {
    if (type === "date") {
      setLabelShow(date?.toLocaleString().split(",")[0] ?? "");
    } else if (type === "select") {
      setLabelShow(selected?.label ?? "");
      selected?.label && setReVal(selected?.label);
    } else {
      setLabelShow(text);
    }
  }, [type, date, text, selected]);

  const handleSelect = (item: Option) => {
    setSelected(item);
    setValue?.(props.name, item.label ?? "");
    clearErrors(props.name);
    setStateId?.(item?.id);
    handleDropdown(false);
  };

  const handleDateChange = (value: Value) => {
    const formattedDate = convert(value);
    const displaydate = display(value);

    setDate(displaydate);
    setCalenderOpen(false);
    setValue?.(props.name, formattedDate ?? "");
    clearErrors(props.name);
  };
  function convert(value: any) {
    const date = new Date(value),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  function display(value: any) {
    const date = new Date(value);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate =
      (month < 10 ? "0" : "") +
      month +
      "-" +
      (day < 10 ? "0" : "") +
      day +
      "-" +
      year;
    return formattedDate;
  }

  const handlePercentNumber = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = ev.target.value.replace(/[^0-9]/g, "");
    setText(`${numericValue}%`);
    setValue?.(props.name, numericValue);
  };
  const setValues = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let values: any = ev.target.value;
    values = parseInt(values.replace(/[$,]/g, ""));
    setValue?.(props.name, values);
  };

  useEffect(() => {
    if (fixedValue) {
      setText(fixedValue);
    }
  }, [fixedValue]);

  return (
    <div className={styles.container} style={props.style ?? {}}>
      {labelText &&
        (login ? (
          <div className="flex gap-1">
            <label className="font-raleway  text-[16px] sm:text-[20px]">
              Hey{" "}
            </label>
            <span className=" text-[20px] sm:text-[25px] font-extrabold   text-[#50b56e]">
              {" "}
              {firstName}
            </span>
            <label className="font-raleway  text-[16px] sm:text-[20px]">
              {labelText}
            </label>
          </div>
        ) : (
          <label className="font-raleway text-[16px] sm:text-[20px]">
            {labelText}
          </label>
        ))}
      <div className={clsx(styles.inputBox, "flex relative")}>
        <input
          className={clsx(
            styles.input,
            "flex-1 font-raleway decoration-green-300  placeholder:sm:text-sm p-3"
          )}
          id="myInput"
          placeholder={placeholder}
          {...register?.(props.name)}
          onFocus={() => handleDropdown(true)}
          onChange={(ev) => {
            onChangeHandler(ev);
          }}
          onKeyDown={handlePress}
          value={reVal ? reVal : labelShow}
          // value={reVal ? reVal : labelShow}
          autoComplete="off"
          maxLength={maxLength}
        />

        {type === "date" && calendarOpen && (
          <Calendar
            className="absolute text-black right-2 bottom-2 z-10"
            onChange={handleDateChange}
            value={date}
            minDate={new Date()}
          />
        )}

        {type === "select" && open && (
          <div
            className="top-[40px] absolute  right-0 z-10 mt-2 w-56 h-[300px] overflow-scroll origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none !w-full"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div className="py-1" role="none">
              {filteredItems?.map((item, index) => (
                <p
                  key={index}
                  className="font-raleway cursor-pointer hover:bg-gray-100  text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabIndex={-1}
                  id="menu-item-0"
                  onClick={() => handleSelect(item)}
                >
                  {item.label}
                </p>
              ))}
            </div>
          </div>
        )}
        {type === "date" && (
          <div>
            <img src={calender} onClick={() => setCalenderOpen(true)} />
          </div>
        )}
        {type === "select" && (
          <DownArrow
            className="cursor-pointer"
            style={{ height: "100%", fontSize: "10px" }}
            onClick={toggleDropdown}
          />
        )}
      </div>

      {/* {error && <span className={styles.error}>{error}</span>} */}
      {error && (
        <span className={styles.error}>
          {props.name === "taxRate" ? "Please enter the tax rate." : ""}
          {props.name === "state" ? "Please select the state." : ""}
          {props.name === "grossIncome" ? "Please enter the gross income." : ""}
        </span>
      )}
    </div>
  );
};

export default Input;
