import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../configs/env.development";

export const proformaApi = createApi({
  reducerPath: "proformaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.baseUrl}/api/proforma`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<ApiResponse<ProfromaDataResponse>, ProformaLogin>({
      query: (body) => ({
        url: "proforma-login",
        method: "POST",
        body: body,
      }),
    }),
    getDefaultProformaValues: builder.query<
      ApiResponse<GetDefaultvalues[]>,
      void
    >({
      query: () => ({
        url: "/get-proforma-values",
        method: "GET",
      }),
    }),
  }),
});

export const { useLoginMutation, useGetDefaultProformaValuesQuery } =
  proformaApi;
