import { useState } from "react";
import { IColor } from "../EpcPopUp";
import { Note } from "./Note";
import { useGetAllUploadsQuery } from "../../../../services/epcApi";
import { Page } from "./Page";
import Loader from "../../../Loader";

interface IProp {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setColor: React.Dispatch<React.SetStateAction<IColor>>;
  docId: string | undefined;
  projectId: string | undefined;
  submitting: boolean;
}

export const Popup = ({
  page,
  setPage,
  setColor,
  docId,
  projectId,
  submitting,
}: IProp) => {
  const { data: uploadsList, refetch } = useGetAllUploadsQuery(docId || "");
  const [notePopup, setNotePopup] = useState(false);

  const buttonArray: (keyof IColor)[] = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
  ];

  const handleReject = () => {
    setNotePopup(true);
    setColor((prevState) => ({
      ...prevState,
      [buttonArray[page - 1]]: "reject",
    }));
    setPage((prev) => prev + 1);
  };

  const handleAccept = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setColor((prevState) => ({
      ...prevState,
      [buttonArray[page - 1]]: "accept",
    }));
    setPage((prev) => prev + 1);
  };

  return (
    <>
      {notePopup ? (
        <Note setNotePopup={setNotePopup} projectId={projectId} />
      ) : (
        <>
          {/* {component} */}
          <Page page={page} docId={docId} projectId={projectId} />
          <div className="flex justify-between w-full md:px-14 absolute bottom-0 pb-4">
            <button
              className={`h-12 w-24 border-2 border-red-800 rounded-md font-bold text-red-800 ${
                page == 8 && "invisible"
              }`}
              onClick={handleReject}
            >
              REJECT
            </button>
            {page == 8 ? (
              <button
                className="h-12 w-24 rounded-md font-bold border-2 text-cyan-600"
                disabled={submitting}
              >
                {submitting ? <Loader isLoading={true} size={30} /> : "SUBMIT"}
              </button>
            ) : (
              <button
                className="h-12 w-24 rounded-md font-bold border-2 border-green-800 text-green-800"
                onClick={handleAccept}
                type="button"
              >
                ACCEPT
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};
