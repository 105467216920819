import React, { useState } from "react";
import CustomInput from "../../CustomInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ppaProjectSchema, siteSchema } from "../../../yup";
import { colors } from "../../../utils/theme";
import CustomButton from "../../Button/CustomButton";
import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa";

import {
  endEpcJourney,
  nextStep,
  setStep,
} from "../../../store/reducers/epcReducer";

const Step3 = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ppaProjectSchema),
  });
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const goToNext = () => {
    dispatch(nextStep());
  };

  const onSubmit = (values: any) => {
    console.log(values);
    goToNext();
    reset();
  };

  const handleCheckClick = () => {
    setIsChecked((prev) => !prev);
  };

  return (
    <div className=" h-[90%] w-1/2 flex flex-col items-start justify-center ">
      <p className={`text-[${colors.green}] font-bold text-2xl`}>
        Monthly PPA online calculator
      </p>
      <p className={`text-[${colors.green}] font-normal text-xl`}>
        Enter project data to get PPA pricing. All fields are mandatory
      </p>
      <form className=" flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full my-3">
          <CustomInput
            placeholder="PV SIZE (W)"
            name="pvSize"
            type="text"
            register={register}
            error={errors.pvSize}
          />
        </div>
        <div className="w-full my-3">
          <CustomInput
            placeholder="BATTERY SIZE (W/h)"
            name="batterySize"
            type="text"
            register={register}
            error={errors.batterySize}
          />
        </div>
        <div className="w-full my-3">
          <CustomInput
            placeholder="YEAR 1 PRODUCTION (kWh)"
            name="yearOneProduction"
            type="text"
            register={register}
            error={errors.yearOneProduction}
          />
        </div>
        <div className="w-full flex gap-4 my-4">
          <div className="w-[5%]">
            <div
              className={` ${
                !isChecked
                  ? `border-1 border border-[${colors.green}]`
                  : `bg-[${colors.green}]`
              } h-[35px] w-[35px] rounded-full flex items-center justify-center`}
              onClick={handleCheckClick}
            >
              {isChecked && <FaCheck color="white" />}
            </div>
          </div>
          <div className={`text-[${colors.green}] w-[95%]`}>
            I confirm that the annual production is not greater than 100% of the
            last 12 months of usage. This a mandatory Inception program
            requirement.
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center">
          <CustomButton buttonLabel="Get rate" disabled={!isChecked} />
        </div>
      </form>
      <button
        onClick={() => {
          dispatch(endEpcJourney());
        }}
        className={`underline self-center text-[${colors.green}] text-lg mt-5`}
      >
        Back to dashboard
      </button>
    </div>
  );
};

export default Step3;
