import styles from "./index.module.css";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

export const GemsPopup = ({
  setGemsPopup,
}: {
  setGemsPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div
      className={`${styles.popupContainer} ${styles.gemsContainer}`}
      onClick={() => setGemsPopup(false)}
    >
      <div
        className={`${styles.popup} ${styles.gemsPopup}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.gemBox}>
          <p className="text-center text-black text-2xl">
            GEMS Application Review Checklist
          </p>
          <div>
            <p className="text-green-700 mt-8 mb-2 text-xl">
              Step-1: Applicant [Rate payer] Eligibility
            </p>
            <p className="font-bold ml-5">
              Items required from Homeowner or Renter Applicant(s) and Landlord
              to HGIA:
            </p>
            <ul className="ml-16">
              <div className="flex ">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  Completed and signed{" "}
                  <strong>GEMS Homeowner OR Rental Application</strong>
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  Completed and signed <strong>Utility Authorization</strong>{" "}
                  Form
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  Copy of{" "}
                  <strong>
                    Driver's license or valid Government issued ID
                  </strong>
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  <strong>Most recent utility bill</strong> (must have at least
                  6 month history)
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  <strong>
                    Copy of original DHHL lease or other ground lease
                  </strong>{" "}
                  (if applicable)
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  Copy of Trust (if title to property held in a Trust)
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  <strong>Trust review fee</strong> of $167.54 per Trust. Please
                  make check payable to the Hawaii Green Infrastructure
                  Authority
                </li>
              </div>
            </ul>
          </div>

          <div>
            <p className="text-green-700 mt-8 mb-2 text-xl">
              Step-2: Project Eligibility
            </p>
            <p className="font-bold ml-5">
              Item required from Homeowner or Landlord and/or Contractor to
              HGIA:
            </p>
            <p className="font-bold ml-5">For Solar PV installations:</p>
            <ul className="ml-16">
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  Executed Sales Contract or Addendum signed by contractor and
                  Property Owner(s) to include:
                </li>
              </div>
              <div className="ml-6">
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">
                    System Cost: $_____{" "}
                    <span className="text-red-500">
                      (may not exceed $4.50/Watt)
                    </span>
                  </li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">System Size: _____ kW</li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">Inverter Size: _____ kW</li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">
                    Extimated Year 1 Production: _____ kWh{" "}
                    <span className="text-red-500">
                      (may not exceed annual historial kWh consumption from
                      utility bill)
                    </span>
                  </li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">Battery Cost: _____ kW</li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">Battery Size: _____ kW (nameplate)</li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 "># of batteries: _____</li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">
                    Equipment description (#panels, #inverters, make, model,
                    monitoring equipment, etc.)
                  </li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">10-year Workmanship Warranty</li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">20-year Manufacturer's Warranty</li>
                </div>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  <strong>Conditional Utility Interconnection Approval</strong>
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  <strong>
                    NREL PV Watts Calculator{" "}
                    <span className="text-blue-500">
                      ( https://www.some-link.com )
                    </span>
                    Printout
                  </strong>
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  <strong>PV Watts Calculator Certification</strong> completed
                  and executed by Contractor (include an explanation if the
                  tolarance exceeds */- 10%)
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="mt-1" />
                </span>
                <li className="ml-2">
                  <strong>For NEM+</strong>, provide the following information
                  on the existing solar PV system.
                </li>
              </div>
              <div className="ml-6">
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">
                    Year solar PV system installed _____
                  </li>
                </div>
                <div className="flex">
                  <span className="w-4">
                    <MdCheckBoxOutlineBlank className="text-sm mt-1" />
                  </span>
                  <li className="ml-2 ">System Size: _____ kW</li>
                </div>
              </div>
            </ul>
          </div>

          <div>
            <p className="mt-8 mb-2 text-xl font-bold">
              Contractor Checklist for Residential On-Bill Program Installation
            </p>
            <ul className="ml-8">
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="text-xs mt-1.5" />
                </span>
                <li className="ml-2 ">
                  <strong>Revised Sales Contract</strong> - with the breakdown
                  of contractor discounts and/or down payment accounts as
                  applicable. The system size and estimated year 1 product must
                  remain the same as the original contract
                </li>
              </div>
              <div className="flex">
                <span className="w-4">
                  <MdCheckBoxOutlineBlank className="text-xs mt-1.5" />
                </span>
                <li className="ml-2 ">
                  <strong>
                    Upon receipt of additional counter-offer acceptance
                    documents, HGIA shall provide Applicant and contractor in
                    final approval letter category below.
                  </strong>
                </li>
              </div>
            </ul>
          </div>

          <div className="mt-4">
            <p>
              NOTE: Post approval changes [size, interconnection, production,
              etc.] will require re-evaluation and re-issuance of project
              approval or denial by HGIA
            </p>
          </div>
          <div className="mt-4 text-right">
            <button
              className="text-green-700 text-md"
              onClick={() => setGemsPopup(false)}
            >
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
