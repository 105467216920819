import clsx from "clsx";

interface Props<T> {
  fields: string[];
  data: any[][];
  headerFields?: string[];
  className?: string;
  name?: string;
}

const TableTop = <T,>(props: Props<T>) => {
  const { fields, data, headerFields = [], className, name = "" } = props;
  return (
    <div className="flex justify-start w-full">
      <div className="w-full sm:w-1/2">
        {" "}
        <table
          className={clsx(
            "w-full divide-y divide-gray-200 text-center",
            className
          )}
        >
          <tbody>
            <tr>
              <td
                colSpan={fields.length}
                className="border border-[#5A8701] py-1 pl-4 "
              >
                {" "}
                <span className="text-md font-semibold">BENEFITS SUMMARY</span>
              </td>
            </tr>
            {data.map((row: any, index: number) => (
              <tr key={index} className="border p-6">
                {row.map((data: any, index: number) => (
                  <td
                    key={index}
                    className={clsx(
                      `text-sm border border-[#5A8701] text-black px-2 py-1`,
                      name
                    )}
                  >
                    {data}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableTop;
