import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ProgressBarProps {
  value: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value }) => {
  const data = {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: ["rgba(54, 162, 235, 0.4)", "rgba(255, 99, 132, 0.4)"],
        borderWidth: 0,
        cutout: "73%",
      },
    ],
  };

  const options = {
    rotation: 270,
    tooltips: { enabled: false },
    circumference: 180,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="flex justify-center w-[100px] mt-8 ">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default ProgressBar;
