import clsx from "clsx";
import "./index.module.css";

interface Props<T> {
  fields: string[];
  data: any;
  headerFields?: string[];
  className?: string;
  name?: string;
}

const Table = <T,>(props: Props<T>) => {
  const { fields, data, headerFields = [], className, name = "" } = props;
  return (
    <div className="flex flex-col">
      <div className="">
        <table
          className={clsx("min-w-full divide-y divide-gray-200", className)}
        >
          <tbody>
            <tr className="border border-x-0 border-[#5A8701]">
              {headerFields.map((header, index) => (
                <td className={` py-1 text-center`} key={index}>
                  <span className=" text-black text-md uppercase  font-semibold ">
                    {header}
                  </span>
                </td>
              ))}
            </tr>
          </tbody>
          <tbody>
            {data.map((row: any, index: number) => {
              return (
                <tr className="" key={index}>
                  {row.map((data: any, index: number) => (
                    <>
                      <td
                        key={index}
                        className={clsx(
                          `${
                            index == 0
                              ? "border-r-1 border-l-0 border border-[#5A8701]"
                              : "border-x-0 border border-[#5A8701]"
                          } text-sm text-black px-4 py-1 text-center`,
                          index === 0 ? name : "w-[100px] min-w-[150px]"
                        )}
                      >
                        {data ? data.toLocaleString().trim() : ""}
                      </td>
                    </>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
