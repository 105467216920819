export const DocumentTabledata = [
  { heading: "General", detail: "Asset Purchase Agreement." },
  {
    heading: "Asset Detail",
    detail: "List Of Assets Purchased In A Given Year.",
  },
  { heading: "Detailed", detail: "This is some detailed information." },
  { heading: "CAF", detail: "Client Acknowledgement Form." },
];
export const DocumentTable2data = [
  {
    heading: "General",
    detail: "Actual Income Recognition For The Promissory Note",
  },
  { heading: "Detailed", detail: "Example Chart Of Accounts" },
  {
    heading: "Customer List	",
    detail: "List Of All JEs By Host Customer Name.",
  },
  { heading: "Form 3468	", detail: "Itemized Guide For Filing Form 3468" },
  {
    heading: "Investor Summary",
    detail: "Year End Summary Of Purchased Assets.",
  },
  { heading: "JE View Of Assets	", detail: "Journal Entry Ready Template." },
];
export const TrancheTabledata = [
  { heading: "2d", detail: "Engineering & Design Package" },
  {
    heading: "Close Out Report",
    detail: "Project Installation With Photographs",
  },
  {
    heading: "Invoice",
    detail: "Installer Payment On Substantial Completion.",
  },
  { heading: "M1", detail: "Verification Of Substantial Completion" },
  {
    heading: "PPA",
    detail: "Prepaid Power Purchase Agreement With Host Customer",
  },
  { heading: "SOW", detail: "Approved Technical Scope Of Work" },
  { heading: "Title", detail: "Property Search" },
  { heading: "UB", detail: "Utility Bill" },
];

export const documents = [
  { name: "IF BOS_Test 100K T1_06.05.2024.pdf" },
  { name: "IF BOS_Test 100K T1_06.05.2024.pdf" },
  { name: "IF BOS_Test 100K T1_06.05.2024.pdf" },
  { name: "IF BOS_Test 100K T1_06.05.2024.pdf" },
  { name: "IF BOS_Test 100K T1_06.05.2024.pdf" },
  { name: "IF BOS_Test 100K T1_06.05.2024.pdf" },
  { name: "IF BOS_Test 100K T1_06.05.2024.pdf" },
];
export const folders = [
  { name: "Test Tranche T1 2024" },
  { name: "Test Tranche T2 2024" },
  { name: "Test Tranche T2 2024" },
  { name: "Test Tranche T2 2024" },
  { name: "Test Tranche T2 2024" },
];
