const config: AppConfig = {
  baseUrl: "https://api.inceptionfinancial.app",
  // baseUrl: "http://192.168.1.148:5000",
  // baseUrl: "http://0.0.0.0:5000",
  // baseUrl: "http://localhost:5000",
  clientPortalUrl: "https://client.inceptionfinancial.app",
  epcPortalUrl:
    "https://investorsportal-797836204.development.catalystserverless.com/server/epc_web_portal/",
  ZD_CSRF_TOKEN: "a54f757b-c934-4376-9874-2f8c2886059b",
  // mainSpreadsheetId: "1JrdDcwyVsVEi6Kw6btWaMrVcvTUHbXkS2xECXVFBYcA", // testing
  // sharedFolderId: "1l1uCvT3bFqL42gvKgRu4ZuocDienLcPH", // testing
  // masterFileId: "1GWgGGU42JuB_qgzBqFIPQ7yxefDO1DuphlTyvEAuJ5s", //testing
  mainSpreadsheetId: "1ZS9eMf_mnY4S7VOqafETOUxV8kDHlhEGjw_e1nkrKWA",
  sharedFolderId: "1YThLt3Xev1eQWqaQyFQUqrdLUvOPavEE",
  masterFileId: "1OO2weqviEgcv9kA39yGIBrmHQkymaHgNrKypb1ml1bM",
  ampVantageFolderId: "1X49b1wI1OXEqOntiWg_9RyxQoPwWlMmb",
  ampVantagePdfFolderId: "1a7ED8HbX5xIj2liwy_wK4QhllleqFTEt",
  slideId: "1gp1Kr3yZ20ScAO5NK9oBHfa_UPD8nsk5rbDNH7Ldjec",
};

export default config;
