import { IoMdDownload } from "react-icons/io";
import {
  useDownloadDocumentMutation,
  useGetAllUploadsQuery,
} from "../../../../services/epcApi";
import { useState } from "react";
import Loader from "../../../Loader";
import { MdOpenInNew } from "react-icons/md";

interface IFileData {
  Project_Opportunity_Docs_name: string;
  Project_Opportunity_Docs_id: string;
  attachment_id: string;
  file_id: string;
  file_name: string;
}

interface pageProps {
  page: number;
  // files: IFileData[]
  docId?: string;
  projectId?: string;
}

export const Page = ({ page, docId, projectId }: pageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  const {
    data: uploadsList,
    refetch,
    isLoading: dataLoading,
  } = useGetAllUploadsQuery(docId || "");
  const [downloadDocument] = useDownloadDocumentMutation();
  const [clicked, setClicked] = useState("");

  const download = async (item: IFileData) => {
    setIsDownload(true);
    setClicked(item.attachment_id);
    try {
      const sendData = {
        attachment_id: item.attachment_id,
        crm_Project_Opportunity_ID: projectId || "",
      };
      const response = (await downloadDocument(sendData)) as { data: any };
      const data = response.data;
      if (!data) {
        throw new Error("File not downloaded");
      }
      const blob = data;
      const url = window.URL.createObjectURL(blob);
      console.log(url, "URL");
      const a = document.createElement("a");
      a.href = url;
      a.download = item.file_name;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsDownload(false);
      setClicked("");
    }
  };

  const viewDocument = async (item: IFileData) => {
    setIsLoading(true);
    setClicked(item.attachment_id);
    try {
      const sendData = {
        attachment_id: item.attachment_id,
        crm_Project_Opportunity_ID: projectId || "",
      };
      const response = (await downloadDocument(sendData)) as { data: any };
      const data = response.data;

      if (!data) {
        throw new Error("File not downloaded");
      }

      const blob = new Blob([data], { type: "image/jpeg" });
      const url = window.URL.createObjectURL(blob);

      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setIsLoading(false);
      setClicked("");
    }
  };

  const content = [
    {
      text: "Completed and signed GEMS Homeowner OR Rental Application",
      label: "homeOwner",
    },
    {
      text: "Completed and signed Utility Authorization Form",
      label: "auth",
    },
    {
      text: `Copy of Driver's license or valid Government issued ID`,
      label: "customerId",
    },
    {
      text: "Most recent utility bill (must have at least 6 month history)",
      label: "utility",
    },
    {
      text: "Copy of original DHHL lease or other ground lease",
      label: "title",
    },
    {
      text: "Copy of Trust (if title to property held in a Trust)",
      label: "trust",
    },
    {
      text: "Trust review fee of $167.54 per Trust. Please make check payable to the Hawaii Green Infrastructure Authority",
      label: "proposal",
    },
    {
      text: "Do you want to submit this form?",
      label: "",
    },
  ];

  const files = uploadsList?.data as IFileData[];
  console.log("files", files);

  return (
    <div className="pt-4 mx-4">
      <p className="text-slate-700 text-xl">{content[page - 1].text}</p>
      <div className="flex flex-wrap mt-4  h-36 overflow-y-auto">
        {dataLoading ? (
          <Loader isLoading={true} size={30} />
        ) : (
          page < content.length &&
          !files?.filter((item) => item.file_name == content[page - 1].label)
            .length && <p className="m-auto">no file found</p>
        )}
        {files
          ?.filter((item) => item.file_name == content[page - 1].label)
          .map((item, index) => (
            <div
              key={item.attachment_id}
              className="flex border-2 border-slate-900 rounded-md h-12 text-xl items-center justify-around text-slate-800 m-auto my-1 "
            >
              <span className="flex items-center h-full px-2">
                {item.file_name + "_" + (index + 1)}
                <button
                  type="button"
                  disabled={item.attachment_id == clicked && isDownload}
                  className="p-1 m-1 border border-slate-500 rounded-full"
                  onClick={() => download(item)}
                >
                  {item.attachment_id == clicked && isDownload ? (
                    <Loader isLoading={true} size={20} />
                  ) : (
                    <IoMdDownload />
                  )}
                </button>
                <button
                  type="button"
                  disabled={item.attachment_id == clicked && !isDownload}
                  className="p-1 m-1 border border-slate-500 rounded-full"
                  onClick={() => viewDocument(item)}
                >
                  {item.attachment_id == clicked && !isDownload ? (
                    <Loader isLoading={true} size={20} />
                  ) : (
                    <MdOpenInNew />
                  )}
                </button>
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};
