import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import CalendlyComponent from "../CalendlyComponent";
import { UserState } from "../../store/reducers/stageReducer";
import { useGetEventDetailQuery } from "../../services/api";
import moment from "moment-timezone";

interface Props {
  stageData: UserState;
}

const Tile2 = ({ stageData }: Props) => {
  const { data: eventDetail, refetch: refetchEvent } = useGetEventDetailQuery();
  const [isOpenCalendly, setIsOpenCalendly] = useState(false);
  const [isEventTrigger, setIsEventTrigger] = useState(false);
  const [isScheduleDatePasses, setIsScheduleDatePasses] = useState(false);
  const [time, setTime] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [scheduledDate, setScheduledDate] = useState<ScheduledDate>();

  const formatDates = (date: string) => {
    const newDate = moment(date).format("DD MMM YYYY");
    return newDate;
  };
  const compareDates = (
    date1: string,
    date2: string,
    hours: number,
    currentHours: number
  ) => {
    const momentDate1 = moment(date1, "DD MMM YYYY");
    const momentDate2 = moment(date2, "DD MMM YYYY");
    if (momentDate1.isBefore(momentDate2)) setIsScheduleDatePasses(true);
    else if (momentDate1.isAfter(momentDate2)) setIsScheduleDatePasses(false);
    else if (hours <= currentHours) setIsScheduleDatePasses(true);
    else setIsScheduleDatePasses(false);
  };

  useEffect(() => {
    if (eventDetail?.data) {
      const { start_time, end_time } = eventDetail?.data;
      const startDate = formatDates(start_time);
      const endDate = formatDates(end_time);
      const endDateForTime = new Date(end_time);
      const hours = endDateForTime.getHours();
      const currentDate = formatDates(new Date().toString());
      compareDates(startDate, currentDate, hours, new Date().getHours());

      const time = moment(start_time).local().format("h:mm A");
      setTime(time);

      const timezone = moment.tz.guess();
      setTimeZone(timezone);
      setScheduledDate({
        start_date: startDate,
        end_date: endDate,
      });
    }
  }, [eventDetail]);

  useEffect(() => {
    refetchEvent();
  }, [isEventTrigger]);

  return (
    <div className={`bg-[#FEFCFB] rounded-xl w-full pt-1 shadow-lg h-[390px]`}>
      <div>
        <h2
          className="text-[25px] mb-[8px] p-1 text-black font-normal"
          style={{ fontFamily: "Signika" }}
        >
          {stageData.cpaCallComplete
            ? "Schedule a Call with Inception Financial"
            : "Next Step: Schedule a Call with CPA"}
        </h2>
        <div className="text-[14px] border-t-4 pt-4 px-4 border-[#EFEFF4]">
          {isScheduleDatePasses
            ? "Have additional questions? We are ready to meet with you! Schedule a call using the button below."
            : "It is time to schedule a call between you, the Inception Team and your CPA. Please choose the day and time that works best for you and your advisor."}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center cursor-pointer h-[60%]">
        <div className="flex justify-center items-center h-full">
          {stageData.cpaCallComplete && !isScheduleDatePasses ? (
            <div className=" text-center ">
              <span className="font-semibold text-[17px]">
                Your Call is Scheduled
              </span>
              <div className="flex flex-col p-1 ">
                <span className="p-2 rounded border font-normal">
                  Your Call is Scheduled for {scheduledDate?.start_date} at{" "}
                  {time} {timeZone}
                </span>
              </div>
            </div>
          ) : (
            <button
              onClick={() => setIsOpenCalendly(true)}
              className="bg-[#356C58] text-white rounded-sm text-[17px] py-2 px-4 "
            >
              SCHEDULE A CALL
            </button>
          )}
          <CalendlyComponent
            setIsEventTrigger={setIsEventTrigger}
            isEventTrigger={isEventTrigger}
            isOpen={isOpenCalendly}
            onClose={() => setIsOpenCalendly(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Tile2;
