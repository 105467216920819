function useProformaValues() {
  const setProformaValues = (values: any, toggleValue: any) => {
    const newValuesArray = [
      [`${values.equityContribution != "" ? values.equityContribution : ""}`],
      [],
      [`${toggleValue.isLossLimitation === true ? "Yes" : "No"}`],
      [],
      [],
      [],
      [`${toggleValue.isFederalRevenueSpread === true ? "Yes" : "No"}`],
      [],
      [
        `${
          toggleValue.isFederalRevenueSpread === true
            ? values.monthlyPlacedInService
            : ""
        }`,
      ],
      [],
      [`${values.year1DepreciationUsed}`],
      [],
      [],
      [],
      [],
      [`${values.stateSelector.label}`],
      [],
      [`${values.stateRevenueSpread === true ? "Yes" : "No"}`],
      [],
      [`${values.adjustedGrossIncome != "" ? values.adjustedGrossIncome : ""}`],
      [],
      [`${values.federalMarginalTaxRate}`],
      [],
      [`${values.stateTaxRate}`],
      [],
      [`${values.stateTaxRateYr7to20}`],
      [],
      [`${values.statePTEPayment}`],
      [],
      [`${values.isFederalBonusDepreciation === true ? "Yes" : "No"}`],
      [],
      [`${values.federalBonusDepreciationPercentage}`],
      [],
      [`${values.stateBonusDepreciation === true ? "Yes" : "No"}`],
      [],
      [`${values.stateBonusDepreciationAsFED === true ? "Yes" : "No"}`],
      [],
      [`${values.ITCPercentage}`],
      [],
      [],
      [`${values.developerDevFee}`],
      [],
      [`${values.inceptionDevFee}`],
      [],
      ["10.00%"],
      [],
      ["$0.110"],
      [],
      [], //"Yrs 7-20 Admin Fee Escalator"
      [],
      [],
      ["$350"],
      [],
      ["50%"],
      [],
      [],
      ["100%"],
      [],
      [],
      [20],
      [7],
      ["0.50%"],
      [],
      [],
      ["20%"],
      ["70%"],
      ["10%"],
    ];
    return newValuesArray;
  };

  return { setProformaValues };
}

export default useProformaValues;
