import { createSlice } from "@reduxjs/toolkit";

interface CurrentStep {
  step: number;
  completedStep: number;
}

const initialState: CurrentStep = {
  step: 0,
  completedStep: 0,
};

const stepSlice = createSlice({
  name: "Steptransition",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.step = state.step + 1;
      state.completedStep = Math.max(state.completedStep, state.step - 1);
    },
    backStep: (state) => {
      state.step = state.step - 1;
    },
    setStep: (state, action) => {
      state.step = action.payload;
      state.completedStep = Math.max(state.completedStep, state.step - 1);
    },
  },
});

export const { nextStep, backStep, setStep } = stepSlice.actions;

export default stepSlice.reducer;
