import { yupResolver } from "@hookform/resolvers/yup";
import img from "../../../assets/images/InceptionFinicallyLogo.png";
import { colors } from "../../../utils/theme";
import Button from "../../Button";
import CustomButton from "../../Button/CustomButton";
import CustomInput from "../../CustomInput";
import styles from "./index.module.css";
import { useForm } from "react-hook-form";
import { resetPasswordSchema } from "../../../yup";
import { useEffect, useState } from "react";
import config from "../../../configs/env.development";
import { useLocation, useNavigate } from "react-router-dom";
import { showNotification } from "../../../services/showNotification";
import { useUpdatePasswordMutation } from "../../../services/epcApi";

interface FormValues {
  repeatPassword: string;
  newPassword: string;
}

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("sct");
  const [updatePassword] = useUpdatePasswordMutation();

  const onSubmit = async (values: FormValues) => {
    const data: IUpdate = {
      password: values.newPassword,
      sc_token: token || "",
    };
    try {
      setIsLoading(true);
      const result = await updatePassword(data);
      console.log(result);
      navigate('/epc/dashboard')
    } catch (error) {
      console.log(error, "err----");
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValue(name as "newPassword" | "repeatPassword", value);
    setErrMsg("");
  };

  return (
    <div className={styles.customClass}>
      <div className={styles.content}>
        <div className="w-full flex items-center justify-center">
          <div className="w-[80vw] h-[80vh] relative flex flex-col items-center justify-center  ">
            <div className=" flex justify-start px-10 pb-20 absolute top-0 left-0">
              <img src={img} alt="" className={`${styles.image}`} />
            </div>
            <form
              className="flex flex-col h-1/2 w-full md:w-1/2 items-center justify-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="w-[70%] mb-8">
                <CustomInput
                  placeholder="New Password"
                  name="newPassword"
                  type="password"
                  register={register}
                  error={errors.newPassword}
                  onChange={onChange}
                />
              </div>
              <div className="w-[70%] mb-2">
                <CustomInput
                  placeholder="Repeat Password"
                  name="repeatPassword"
                  type="password"
                  register={register}
                  error={errors.repeatPassword}
                  onChange={onChange}
                />
              </div>
              <div className="w-[70%]">
                {errMsg && (
                  <p className="text-red-500 font-bold text-md">{errMsg}</p>
                )}
              </div>

              <div className="flex flex-col items-center justify-center md:block w-[70%] mt-8">
                <CustomButton
                  buttonLabel="Set password"
                  isLoading={isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
