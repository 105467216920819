import { useEffect, useState } from "react";
import { colors } from "../../../utils/theme";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { turnKeyPriceSchema } from "../../../yup";
import { useDispatch } from "react-redux";
import { endEpcJourney, nextStep } from "../../../store/reducers/epcReducer";

const Step5 = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(turnKeyPriceSchema),
  });
  const dispatch = useDispatch();
  const [monthlyPpaRate, setMonthlyPpaRate] = useState<string>("");
  const [ppaStatus, setPpaStatus] = useState<string>("");
  const link =
    "https://app.isoftpull.com/forms/3fdcc0ad-318c-4bda- bbb2-56944ed4d415/9b5917c9-5500-490c-a1ad-1dfbeb47370b";

  const goToNext = () => {
    dispatch(nextStep());
  };

  const onSubmit = (values: any) => {
    console.log(values);
    goToNext();
    reset();
  };

  useEffect(() => {
    setMonthlyPpaRate("0.300");
    setPpaStatus("valid");
    // setPpaStatus("invalid");
  }, []);

  return (
    <>
      <div className={`h-[90%] w-full flex flex-col items-start`}>
        <div className=" w-full flex items-center justify-center border-[#86b393] border-b-2 pb-6">
          <div className="w-1/2 flex flex-col justify-center items-start my-12 ">
            <p className={`text-4xl mx-auto font-semibold text-[#86b393] mb-8`}>
              Thank you!
            </p>
            <p className={`text-xl text-[#86b393] mb-8 font-medium`}>
              We just sent the Credit Pre-Qualification to the customer email,
              but in case is needed here is the link to the online form:
            </p>
            <a
              href={link}
              className={`text-xl text-center text-[#86b393] font-medium underline`}
            >
              {link}
            </a>
          </div>
        </div>
        <button
          onClick={() => {
            dispatch(endEpcJourney());
          }}
          className={`underline self-center text-[${colors.green}] text-lg mt-8`}
        >
          Back to dashboard
        </button>
      </div>
    </>
  );
};

export default Step5;
