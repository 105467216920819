import { toast as _toast, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ToastInstance {
  success: (message: string, config?: ToastConfig) => void;
  error: (message: string, config?: ToastConfig) => void;
}

interface ToastConfig {
  position?: ToastPosition;
}

export const toast: ToastInstance = _toast;

export const showNotification = (type: string, message: string): void => {
  let position: ToastPosition = "top-center";
  if (type === "error") {
    _toast.error(message, { position });
  } else {
    _toast.success(message, { position });
  }
};
