import React from "react";
import CustomInput from "../../CustomInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { siteSchema } from "../../../yup";
import { colors } from "../../../utils/theme";
import CustomButton from "../../Button/CustomButton";
import { useDispatch } from "react-redux";
import {
  endEpcJourney,
  nextStep,
  setStep,
} from "../../../store/reducers/epcReducer";

const Step2 = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(siteSchema),
  });
  const dispatch = useDispatch();

  const goToNext = () => {
    dispatch(nextStep());
  };

  const onSubmit = (values: any) => {
    console.log(values);
    goToNext();
    reset();
  };

  return (
    <div className=" h-[90%] w-1/2 flex flex-col items-start justify-center ">
      <p className={`text-[${colors.green}] font-bold text-2xl`}>
        Monthly PPA online calculator
      </p>
      <p className={`text-[${colors.green}] font-normal text-xl`}>
        Enter site data to create an opportunity. All fields are mandatory
      </p>
      <form className=" flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full my-6">
          <CustomInput
            placeholder="SITE ADDRESS"
            name="siteAddress"
            type="text"
            register={register}
            error={errors.siteAddress}
          />
        </div>
        <div className="w-full my-6">
          <CustomInput
            placeholder="BLENDED UTILITY RATE ($kW/h)"
            name="blendedUtilityRate"
            type="text"
            register={register}
            error={errors.blendedUtilityRate}
          />
        </div>
        <div className="w-full flex flex-col items-center justify-center">
          <CustomButton buttonLabel="Continue" />
        </div>
      </form>
      <button
        onClick={() => {
          dispatch(endEpcJourney());
        }}
        className={`underline self-center text-[${colors.green}] text-lg mt-5`}
      >
        Back to dashboard
      </button>
    </div>
  );
};

export default Step2;
