import pdfFile from '../../assets/pdf/f3468_Tools_Tips_No_header.pdf'


export const Pdf4 = () => {

    return (
        <>
            <iframe
                src={pdfFile}
                style={{ width: '100%', height: '100vh' }}
            />
        </>
    )
}