import React, { useState } from "react";
import styles from "./index.module.css";
import CalendlyComponent from "../CalendlyComponent";
import { UserState } from "../../store/reducers/stageReducer";

interface Props {
  setIsEventTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  isEventTrigger: boolean;
  time: string;
  timeZone: string;
  stageData: UserState;
  isScheduleDatePasses: boolean;
  scheduledDate: ScheduledDate | undefined;
}

const Tile2 = ({
  setIsEventTrigger,
  stageData,
  time,
  timeZone,
  isEventTrigger,
  scheduledDate,
  isScheduleDatePasses,
}: Props) => {
  const [isOpenCalendly, setIsOpenCalendly] = useState(false);
  return (
    <div className={`${styles.tile1} ${styles.customTile}`}>
      <div className={styles.tile1Container1}>
        <h2 className={styles.heading} style={{ fontFamily: "Signika" }}>
          {stageData.cpaCallComplete
            ? "Schedule a Call with Inception Financial"
            : "Next Step: Schedule a Call with CPA"}
        </h2>
        <div className={styles.tile2desp}>
          {isScheduleDatePasses
            ? "Have additional questions? We are ready to meet with you! Schedule a call using the button below."
            : "It is time to schedule a call between you, the Inception Team and your CPA. Please choose the day and time that works best for you and your advisor."}
        </div>
      </div>
      <div className={styles.tile1Box}>
        <div
          className="mt-6 w-[80%] flex justify-center items-center h-full"
          style={{ backgroundColor: "#fff", borderRadius: "5px" }}
        >
          {stageData.cpaCallComplete && !isScheduleDatePasses ? (
            <div className=" text-center ">
              <span className="font-semibold">Your Call is Scheduled</span>
              <div className="flex flex-col p-1 ">
                <span className="p-2 rounded border">
                  Your Call is Scheduled for {scheduledDate?.start_date} at{" "}
                  {time} {timeZone}
                </span>
              </div>
            </div>
          ) : (
            <button
              onClick={() => setIsOpenCalendly(true)}
              className={styles.tile4Button}
            >
              SCHEDULE A CALL
            </button>
          )}
          <CalendlyComponent
            setIsEventTrigger={setIsEventTrigger}
            isEventTrigger={isEventTrigger}
            isOpen={isOpenCalendly}
            onClose={() => setIsOpenCalendly(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Tile2;
