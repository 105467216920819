import styles from "./index.module.css";
import img from "../../assets/images/InceptionFinicallyLogo.png";
import { useForm } from "react-hook-form";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/Button/CustomButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { entityLoginSchema } from "../../yup";
import { useEntityLoginMutation } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppDispatch } from "../../store/store";
import { setUserLogin } from "../../store/reducers/loginReducer";

const EntityLogin = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(entityLoginSchema),
  });
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [entityLogin, { data, isLoading }] = useEntityLoginMutation();

  const [error, setError] = useState("");

  const onSubmit = async (values: any) => {
    try {
      const { email, pin } = values;
      const loginUser = await entityLogin({
        email: email,
        pin: pin,
      }).unwrap();
      const { data: result } = loginUser;
      localStorage.setItem("token", result.token);
      const user = {
        _id: result._id,
        zohoCustomerId: result.zohoCustomerId,
        firstName: result.firstName,
        lastName: result.lastName,
        steps: result.steps,
      };
      dispatch(setUserLogin(user));
      navigate("/entity-formation");
    } catch (error: any) {
      setError("An error occured");
      console.log(error);
    }
  };

  return (
    <div className={`${styles.container} md:flex justify-center`}>
      <div className=" flex justify-start md:px-10 pb-20 absolute top-0 left-0">
        <a href="https://www.inception.financial/" target="_blank">
          <img src={img} alt="" className={`${styles.image}`} />
        </a>
      </div>

      <div className="w-full">
        <form
          className="md:w-1/3 flex flex-col items-center justify-center m-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full p-2">
            <label className="text-white font-semibold text-xl ">Email</label>
            <CustomInput
              type="email"
              name="email"
              register={register}
              placeholder="Enter your email"
              error={errors.email}
            />
          </div>
          <div className="w-full p-2">
            <label className="text-white font-semibold text-xl ">Pin</label>
            {/* <CustomInput
              type="number"
              name="pin"
              register={register}
              placeholder="Enter your pin"
              error={errors.pin}
            /> */}
            <input
              className="w-full px-4 outline-none h-[42px] font-raleway text-lg bg-white rounded"
              type="number" 
              id="pin" 
              {...register('pin')}
              placeholder="Enter your pin"
            />
            {errors.pin && <div className="text-red-500 text-sm w-[100%] mt-1  font-bold flex items-start justify-start capitalize">{errors.pin.message}</div>}
          </div>
          {error && <div className="text-md text-red-500 ">{error}</div>}
          <div className="w-full mt-6 px-2">
            <CustomButton buttonLabel="SUBMIT" isLoading={isLoading} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EntityLogin;
