import React from "react";
import styles from "./index.module.css";
import EpcLogin from "../EpcLogin";

const EpcJourney = () => {
  let component = <EpcLogin />;
  return (
    <div className={styles.customClass}>
      <div className={styles.content}>{component}</div>
    </div>
  );
};

export default EpcJourney;
