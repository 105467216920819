import { FC, ReactNode } from "react";
import styles from "./index.module.css";
import clsx from "clsx";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  children?: ReactNode;
  classes?: string;
}

const Modal: FC<ModalProps> = ({
  show,
  onClose,
  children,
  classes,
}: ModalProps) => {
  return (
    <>
      {show && (
        <div className={styles.overlay} onClick={onClose}>
          <div
            className={clsx(
              "bg-gray-200 border-none p-10 min-w-[32em] max-w-[45%] min-h-[200px] max-h-[80%] shadow-md flex flex-col justify-center items-center text-base relative box-border",
              classes
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.modalContent}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
